/* You can add global styles to this file, and also import other style files */
$styleVersion: 2211.21.0;
@import './styles/spartacus/index'; //adding custom css to fix the bootstrap conflict of spartacus css with boootstrap 5
@import '../node_modules/material-icons/iconfont/material-icons.css';
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap/scss/grid";
@import '../node_modules/@bdhm/bgs-common-ui-lib/bgs-common-ui-lib.main';



:root{
  .header{
    max-width: 100%;
    margin: 0px;
    padding: 0px;
  }
  @media (min-width: 992px) {

    .header{
      padding: 0px;
    } 
  }
  .TopHeaderSlot{
    width: 100%
  }  
  .LandingPage2Template {
      .Section1 {
            max-width: 100% !important; 
            margin: 0px !important; 
            margin-top: 0px !important;
      }
    }
    .footer{
      max-width: 100%;
      margin: 0px;
      padding: 0px;
    }
    @media (min-width: 992px) {
      .footer{
        padding: 0px;
      } 
    }
    .FooterSlot{
      width: 100%;
    }  
    .navigation{
      max-width: 100%;
      margin: 0px;
      padding: 0px;
    }
    @media (min-width: 992px) {
  
      .navigation{
        padding: 0px;
      } 
    }
  }
  
  header:not(.is-expanded) cx-page-layout.navigation {
     display: block !important; 
}
cx-page-slot.BottomHeaderSlot.has-components {
  display: none;
}
@media (min-width: 992px){
header header {
    padding: 0 0rem !important;
}
}
  body{
      @include boeing_font('./assets/fonts'); // browser build path
    font-family:  "Meso", sans-serif;
  }

.pagination-bar-bottom {
  margin: 40px auto ;
  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #337ab7 !important;
    border-color: #337ab7 !important;
  }
  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #337ab7;
    background-color: #fff;
    border: 1px solid #dee2e6;
}
}
header{
  background: none !important;
}

//error page 
cx-page-layout.ErrorPageTemplate cx-page-slot {
  min-width: 100%;
}
// FONTS

@font-face {
  font-family: 'Meso';
  src:url("./assets/fonts/BoeingMesoRegular.otf?#iefix");
  src:url("./assets/fonts/BoeingMesoRegular.otf?#iefix") format("eot"),
  url("./assets/fonts/BoeingMesoRegular.woff") format("woff"),
  url("./assets/fonts/BoeingMesoRegular.woff2") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: "Meso Bold";
  src:url("./assets/fonts/BoeingMesoBold.otf?#iefix");
  src:url("./assets/fonts/BoeingMesoBold.otf?#iefix") format("eot"),
  url("./assets/fonts/BoeingMesoBold.woff") format("woff"),
  url("./assets/fonts/BoeingMesoBold.woff2") format("woff2");
  font-weight: bold;
}

// Icon Font
@mixin material-icons {
  font-family: "Material Icons";
  /* Support for IE */
  -webkit-font-feature-settings: "liga" on;
  font-feature-settings: "liga" on;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
}

cx-login a {
  display: none !important;
}

