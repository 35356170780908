.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog, cx-close-account-modal .cx-close-account-modal {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog, .modal.fade cx-suggested-addresses-dialog .cx-suggested-addresses-dialog, cx-suggested-addresses-dialog .modal.fade .cx-suggested-addresses-dialog, .modal.fade cx-close-account-modal .cx-close-account-modal, cx-close-account-modal .modal.fade .cx-close-account-modal {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog, .modal.fade cx-suggested-addresses-dialog .cx-suggested-addresses-dialog, cx-suggested-addresses-dialog .modal.fade .cx-suggested-addresses-dialog, .modal.fade cx-close-account-modal .cx-close-account-modal, cx-close-account-modal .modal.fade .cx-close-account-modal {
    transition: none;
  }
}
.modal.show .modal-dialog, .modal.show cx-suggested-addresses-dialog .cx-suggested-addresses-dialog, cx-suggested-addresses-dialog .modal.show .cx-suggested-addresses-dialog, .modal.show cx-close-account-modal .cx-close-account-modal, cx-close-account-modal .modal.show .cx-close-account-modal {
  transform: none;
}
.modal.modal-static .modal-dialog, .modal.modal-static cx-suggested-addresses-dialog .cx-suggested-addresses-dialog, cx-suggested-addresses-dialog .modal.modal-static .cx-suggested-addresses-dialog, .modal.modal-static cx-close-account-modal .cx-close-account-modal, cx-close-account-modal .modal.modal-static .cx-close-account-modal {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content, .modal-dialog-scrollable cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .modal-dialog-scrollable .cx-suggested-addresses-container, .modal-dialog-scrollable cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container, cx-close-account-modal .cx-close-account-modal .modal-dialog-scrollable .cx-close-account-modal-container {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body, .modal-dialog-scrollable cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-body, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .modal-dialog-scrollable .cx-suggested-addresses-body, .modal-dialog-scrollable cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-body, cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .modal-dialog-scrollable .cx-close-account-modal-body {
  overflow-y: auto;
}

.modal-dialog-centered, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog, cx-close-account-modal .cx-close-account-modal {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container, cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-body, cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog, cx-close-account-modal .cx-close-account-modal {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog, cx-close-account-modal .cx-close-account-modal {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content, .modal-fullscreen cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .modal-fullscreen .cx-suggested-addresses-container, .modal-fullscreen cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container, cx-close-account-modal .cx-close-account-modal .modal-fullscreen .cx-close-account-modal-container {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body, .modal-fullscreen cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-body, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .modal-fullscreen .cx-suggested-addresses-body, .modal-fullscreen cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-body, cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .modal-fullscreen .cx-close-account-modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content, .modal-fullscreen-sm-down cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .modal-fullscreen-sm-down .cx-suggested-addresses-container, .modal-fullscreen-sm-down cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container, cx-close-account-modal .cx-close-account-modal .modal-fullscreen-sm-down .cx-close-account-modal-container {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body, .modal-fullscreen-sm-down cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-body, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .modal-fullscreen-sm-down .cx-suggested-addresses-body, .modal-fullscreen-sm-down cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-body, cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .modal-fullscreen-sm-down .cx-close-account-modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content, .modal-fullscreen-md-down cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .modal-fullscreen-md-down .cx-suggested-addresses-container, .modal-fullscreen-md-down cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container, cx-close-account-modal .cx-close-account-modal .modal-fullscreen-md-down .cx-close-account-modal-container {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body, .modal-fullscreen-md-down cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-body, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .modal-fullscreen-md-down .cx-suggested-addresses-body, .modal-fullscreen-md-down cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-body, cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .modal-fullscreen-md-down .cx-close-account-modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content, .modal-fullscreen-lg-down cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .modal-fullscreen-lg-down .cx-suggested-addresses-container, .modal-fullscreen-lg-down cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container, cx-close-account-modal .cx-close-account-modal .modal-fullscreen-lg-down .cx-close-account-modal-container {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body, .modal-fullscreen-lg-down cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-body, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .modal-fullscreen-lg-down .cx-suggested-addresses-body, .modal-fullscreen-lg-down cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-body, cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .modal-fullscreen-lg-down .cx-close-account-modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content, .modal-fullscreen-xl-down cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .modal-fullscreen-xl-down .cx-suggested-addresses-container, .modal-fullscreen-xl-down cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container, cx-close-account-modal .cx-close-account-modal .modal-fullscreen-xl-down .cx-close-account-modal-container {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body, .modal-fullscreen-xl-down cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-body, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .modal-fullscreen-xl-down .cx-suggested-addresses-body, .modal-fullscreen-xl-down cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-body, cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .modal-fullscreen-xl-down .cx-close-account-modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content, .modal-fullscreen-xxl-down cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .modal-fullscreen-xxl-down .cx-suggested-addresses-container, .modal-fullscreen-xxl-down cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container, cx-close-account-modal .cx-close-account-modal .modal-fullscreen-xxl-down .cx-close-account-modal-container {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body, .modal-fullscreen-xxl-down cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-body, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .modal-fullscreen-xxl-down .cx-suggested-addresses-body, .modal-fullscreen-xxl-down cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-body, cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .modal-fullscreen-xxl-down .cx-close-account-modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(31, 123, 192, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-header {
  padding: 1rem 1rem;
}
.modal-body, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-body, cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-body {
  padding: 16px 30px 30px;
}
.modal-content, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container, cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container {
  border-radius: 0;
  border: none;
}
.modal .close {
  font-size: 38px;
  font-weight: 100;
  bottom: 5px;
  position: relative;
  margin-inline-start: 0;
  margin-inline-end: 0;
  align-self: flex-end;
}
@media (max-width: 767.98px) {
  .modal .close {
    margin-bottom: 0;
  }
}
.modal[aria-hidden=false] {
  display: block;
}

.cx-modal-container {
  display: flex;
  align-items: center;
  margin: auto;
  height: 100%;
  overflow-y: auto;
  max-width: 768px !important;
  min-width: 768px !important;
}
@media (max-width: 575.98px) {
  .cx-modal-container {
    margin: 0 !important;
    min-width: 100% !important;
    max-width: 100% !important;
    overflow-y: initial;
    height: 100%;
    overflow-y: auto;
  }
}
@media (min-width: 768px) {
  .cx-modal-container {
    max-width: 768px !important;
    min-width: 768px !important;
  }
}

.cx-modal-header {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  padding-inline-end: 1.875rem;
  padding-bottom: 0;
  padding-inline-start: 1.875rem;
}

.cx-modal-content {
  background-color: var(--cx-color-inverse);
  width: 100%;
}

.cx-modal-footer {
  padding: 0px 27px 30px;
}

@media (min-width: 992px) {
  .cx-asm-dialog {
    max-width: 95% !important;
    min-width: 95% !important;
  }
}
.cx-asm-dialog .cx-modal-content {
  border-radius: 16px;
}

cx-login {
  color: var(--cx-color-text);
}
@media (max-width: 767.98px) {
  cx-login > a {
    padding: 1rem;
    display: block;
  }
}
cx-login .cx-login-greet {
  color: var(--cx-color-text);
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: var(--cx-font-weight-semi);
}
@media (max-width: 767.98px) {
  cx-login .cx-login-greet {
    color: var(--cx-color-text);
    padding: 0.5rem 1rem 0;
    font-size: var(--cx-font-size, 1rem);
    font-weight: var(--cx-font-weight-normal);
    line-height: var(--cx-line-height, 1.6);
  }
}
@media (min-width: 992px) {
  cx-login #account-nav nav > ul > li > button {
    padding-top: 4px;
    padding-bottom: 0;
  }
}

cx-login-form.user-form cx-spinner {
  display: none;
}
cx-login-form.user-form button {
  flex: 100%;
}

.cx-my-account-v2-user {
  border: 1px solid var(--cx-color-medium);
  width: 250px;
  padding: 20px 5px 5px 25px;
  gap: 40px;
  height: 120px;
  margin: 2rem 0rem 0rem 0rem;
}
.cx-my-account-v2-user .cx-name .cx-sign-out {
  text-decoration: underline;
}

cx-close-account {
  display: flex;
  justify-content: center;
}
cx-close-account .cx-info {
  margin: 0 0 3em 0;
}
cx-close-account .cx-btn-group button {
  min-width: 100%;
  text-transform: lowercase;
}
cx-close-account .cx-btn-group button:first-line {
  text-transform: capitalize;
}
cx-close-account .cx-btn-group button:first-child {
  margin-inline-end: 1em;
}
@media (max-width: 575.98px) {
  cx-close-account .cx-btn-group a:first-child {
    margin: 0 0 1em 0;
  }
}
cx-close-account ul {
  padding-inline-start: 20px;
}

cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-header {
  display: flex;
  justify-content: space-between;
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-header .cx-close-account-modal-title {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-confirmation {
  margin-bottom: 0px;
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer {
  display: flex;
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button {
  flex: 0 0 calc(50% - 0.5rem);
  text-transform: lowercase;
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button:first-line {
  text-transform: capitalize;
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button:first-child {
  margin-inline-end: 0.5rem;
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button:last-child {
  margin-inline-start: 0.5rem;
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button:focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}

cx-register form a {
  text-decoration: underline;
}
cx-register form .cx-login-link {
  margin: 1rem 0 0;
}

cx-my-account-v2-email .flex-line {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
cx-my-account-v2-email .headertext {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  padding-bottom: 1.5rem;
  width: 95%;
}
cx-my-account-v2-email .editButton {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.6);
  color: var(--cx-color-primary);
  border: none;
  background-color: var(--cx-color-transparent);
}
cx-my-account-v2-email .text-head {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  min-width: 7.5rem;
  color: var(--cx-color-background-dark);
  width: 15%;
}
cx-my-account-v2-email .value {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.6);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
cx-my-account-v2-email .button {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.6);
  padding-top: 0.8rem;
  padding-inline-end: 1.5rem;
  padding-bottom: 0.8rem;
  padding-inline-start: 1.5rem;
}
cx-my-account-v2-email .button-cancel {
  color: var(--cx-color-primary);
}
cx-my-account-v2-email .email-editing-area {
  width: 100%;
  max-width: 35rem;
}
cx-my-account-v2-email .cx-message-info {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  border-color: var(--cx-color-visual-focus);
  background-color: var(--cx-color-info-accent);
}
cx-my-account-v2-email .cx-message-info .cx-message-icon cx-icon {
  color: var(--cx-color-primary-accent);
}
cx-my-account-v2-email .btn-group {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
}
cx-my-account-v2-email form label {
  padding-bottom: 0.75rem;
}
cx-my-account-v2-email label {
  padding-bottom: 0.3rem;
}

cx-my-account-v2-profile .flex-line {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
cx-my-account-v2-profile .headertext {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  padding-bottom: 1.5rem;
  width: 95%;
}
cx-my-account-v2-profile .editButton {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.6);
  color: var(--cx-color-primary);
  border: none;
  background-color: var(--cx-color-transparent);
}
cx-my-account-v2-profile .text-head {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  min-width: 7.5rem;
  width: 15%;
}
cx-my-account-v2-profile .value {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
cx-my-account-v2-profile .button {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.6);
  padding-top: 0.8rem;
  padding-inline-end: 1.5rem;
  padding-bottom: 0.8rem;
  padding-inline-start: 1.5rem;
}
cx-my-account-v2-profile .button-cancel {
  color: var(--cx-color-primary);
}
cx-my-account-v2-profile .myaccount-editing-area {
  width: 100%;
  max-width: 35rem;
}
cx-my-account-v2-profile .btn-group {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
}
cx-my-account-v2-profile .ng-select .ng-select-container {
  background-color: var(--cx-color-background);
}
cx-my-account-v2-profile form label {
  padding-bottom: 0.75rem;
}
cx-my-account-v2-profile label {
  padding-bottom: 0.3rem;
}

@media (min-width: 575px) {
  cx-my-account-v2-password {
    --cx-max-width: 50%;
  }
}
cx-my-account-v2-password .myaccount-password-header {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  padding-bottom: 1.5rem;
}
cx-my-account-v2-password .myaccount-password-button {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.6);
  padding-top: 0.8rem;
  padding-inline-end: 1.5rem;
  padding-bottom: 0.8rem;
  padding-inline-start: 1.5rem;
}
cx-my-account-v2-password .myaccount-password-button-cancel {
  color: var(--cx-color-primary);
}
cx-my-account-v2-password .myaccount-password-label {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.6);
  min-width: 7.5rem;
  padding-top: 0;
  padding-inline-end: 1.2rem;
  padding-bottom: 0;
  padding-inline-start: 0;
}
cx-my-account-v2-password .myaccount-label-padding {
  padding-bottom: 0.75rem;
}
cx-my-account-v2-password .password-btn-group {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
}
cx-my-account-v2-password .cx-message-info {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  border-color: var(--cx-color-visual-focus);
  background-color: var(--cx-color-info-accent);
}
cx-my-account-v2-password .cx-message-info .cx-message-icon cx-icon {
  color: var(--cx-color-primary-accent);
}

cx-address-book .cx-address-deck {
  padding: 1.25rem 0 0 0;
}
cx-address-book .cx-address-deck-spinner {
  padding: 5rem 0 5rem 0;
}
cx-address-book .cx-form {
  padding-top: 30px;
}
cx-address-book .cx-section-msg {
  margin-bottom: 1.5rem;
}
cx-address-book .cx-address-card {
  padding-bottom: 30px;
}

cx-address-form .cx-address-form-btns {
  padding: 1rem 0;
  justify-content: center;
}
@media (max-width: 767.98px) {
  cx-address-form .cx-address-form-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 575.98px) {
  cx-address-form .cx-address-form-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 767.98px) {
  cx-address-form .cx-address-form-btns .btn-secondary {
    margin: 0 0 1rem;
  }
}

cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-header {
  display: flex;
  justify-content: space-between;
}
cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-header .cx-suggested-addresses-title {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-footer .cx-dialog-buttons:focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}
@media (min-width: 768px) {
  cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-footer .cx-dialog-buttons {
    flex: 0 0 calc(50% - 0.5rem);
  }
  cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-footer .cx-dialog-buttons:last-child {
    margin-inline-start: 1rem;
  }
}
@media (max-width: 575.98px) {
  cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-footer .cx-dialog-buttons {
    width: 100%;
    margin-bottom: 0.625rem;
  }
}

.user-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}
@media (min-width: 768px) {
  .user-form {
    width: 50%;
    --cx-max-width: 50%;
  }
}
.user-form form {
  display: contents;
}
.user-form form label {
  all: unset;
  flex: 100%;
  margin-bottom: 0.375rem;
}
.user-form button,
.user-form a {
  flex: 0 0 calc(50% - 10px);
  margin-top: 0.5rem;
}