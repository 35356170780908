.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground, cx-tracking-events .cx-consignment-tracking-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog, .modal.fade cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground, cx-replenishment-order-cancellation-dialog .modal.fade .cx-cancel-replenishment-dialog-foreground, .modal.fade cx-tracking-events .cx-consignment-tracking-dialog, cx-tracking-events .modal.fade .cx-consignment-tracking-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog, .modal.fade cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground, cx-replenishment-order-cancellation-dialog .modal.fade .cx-cancel-replenishment-dialog-foreground, .modal.fade cx-tracking-events .cx-consignment-tracking-dialog, cx-tracking-events .modal.fade .cx-consignment-tracking-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog, .modal.show cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground, cx-replenishment-order-cancellation-dialog .modal.show .cx-cancel-replenishment-dialog-foreground, .modal.show cx-tracking-events .cx-consignment-tracking-dialog, cx-tracking-events .modal.show .cx-consignment-tracking-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog, .modal.modal-static cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground, cx-replenishment-order-cancellation-dialog .modal.modal-static .cx-cancel-replenishment-dialog-foreground, .modal.modal-static cx-tracking-events .cx-consignment-tracking-dialog, cx-tracking-events .modal.modal-static .cx-consignment-tracking-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content, .modal-dialog-scrollable cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .modal-dialog-scrollable .cx-cancel-replenishment-dialog-content, .modal-dialog-scrollable cx-tracking-events .cx-consignment-tracking-dialog .cx-consignment-tracking-container, cx-tracking-events .cx-consignment-tracking-dialog .modal-dialog-scrollable .cx-consignment-tracking-container {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body, .modal-dialog-scrollable cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .cx-cancel-replenishment-dialog-body, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .modal-dialog-scrollable .cx-cancel-replenishment-dialog-body {
  overflow-y: auto;
}

.modal-dialog-centered, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground, cx-tracking-events .cx-consignment-tracking-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content, cx-tracking-events .cx-consignment-tracking-dialog .cx-consignment-tracking-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .cx-cancel-replenishment-dialog-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .cx-cancel-replenishment-dialog-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .cx-cancel-replenishment-dialog-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground, cx-tracking-events .cx-consignment-tracking-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground, cx-tracking-events .cx-consignment-tracking-dialog {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg, cx-tracking-events .cx-consignment-tracking-dialog,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content, .modal-fullscreen cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .modal-fullscreen .cx-cancel-replenishment-dialog-content, .modal-fullscreen cx-tracking-events .cx-consignment-tracking-dialog .cx-consignment-tracking-container, cx-tracking-events .cx-consignment-tracking-dialog .modal-fullscreen .cx-consignment-tracking-container {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header, .modal-fullscreen cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .cx-cancel-replenishment-dialog-header, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .modal-fullscreen .cx-cancel-replenishment-dialog-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body, .modal-fullscreen cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .cx-cancel-replenishment-dialog-body, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .modal-fullscreen .cx-cancel-replenishment-dialog-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content, .modal-fullscreen-sm-down cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .modal-fullscreen-sm-down .cx-cancel-replenishment-dialog-content, .modal-fullscreen-sm-down cx-tracking-events .cx-consignment-tracking-dialog .cx-consignment-tracking-container, cx-tracking-events .cx-consignment-tracking-dialog .modal-fullscreen-sm-down .cx-consignment-tracking-container {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header, .modal-fullscreen-sm-down cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .cx-cancel-replenishment-dialog-header, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .modal-fullscreen-sm-down .cx-cancel-replenishment-dialog-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body, .modal-fullscreen-sm-down cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .cx-cancel-replenishment-dialog-body, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .modal-fullscreen-sm-down .cx-cancel-replenishment-dialog-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content, .modal-fullscreen-md-down cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .modal-fullscreen-md-down .cx-cancel-replenishment-dialog-content, .modal-fullscreen-md-down cx-tracking-events .cx-consignment-tracking-dialog .cx-consignment-tracking-container, cx-tracking-events .cx-consignment-tracking-dialog .modal-fullscreen-md-down .cx-consignment-tracking-container {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header, .modal-fullscreen-md-down cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .cx-cancel-replenishment-dialog-header, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .modal-fullscreen-md-down .cx-cancel-replenishment-dialog-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body, .modal-fullscreen-md-down cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .cx-cancel-replenishment-dialog-body, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .modal-fullscreen-md-down .cx-cancel-replenishment-dialog-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content, .modal-fullscreen-lg-down cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .modal-fullscreen-lg-down .cx-cancel-replenishment-dialog-content, .modal-fullscreen-lg-down cx-tracking-events .cx-consignment-tracking-dialog .cx-consignment-tracking-container, cx-tracking-events .cx-consignment-tracking-dialog .modal-fullscreen-lg-down .cx-consignment-tracking-container {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header, .modal-fullscreen-lg-down cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .cx-cancel-replenishment-dialog-header, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .modal-fullscreen-lg-down .cx-cancel-replenishment-dialog-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body, .modal-fullscreen-lg-down cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .cx-cancel-replenishment-dialog-body, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .modal-fullscreen-lg-down .cx-cancel-replenishment-dialog-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content, .modal-fullscreen-xl-down cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .modal-fullscreen-xl-down .cx-cancel-replenishment-dialog-content, .modal-fullscreen-xl-down cx-tracking-events .cx-consignment-tracking-dialog .cx-consignment-tracking-container, cx-tracking-events .cx-consignment-tracking-dialog .modal-fullscreen-xl-down .cx-consignment-tracking-container {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header, .modal-fullscreen-xl-down cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .cx-cancel-replenishment-dialog-header, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .modal-fullscreen-xl-down .cx-cancel-replenishment-dialog-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body, .modal-fullscreen-xl-down cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .cx-cancel-replenishment-dialog-body, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .modal-fullscreen-xl-down .cx-cancel-replenishment-dialog-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content, .modal-fullscreen-xxl-down cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .modal-fullscreen-xxl-down .cx-cancel-replenishment-dialog-content, .modal-fullscreen-xxl-down cx-tracking-events .cx-consignment-tracking-dialog .cx-consignment-tracking-container, cx-tracking-events .cx-consignment-tracking-dialog .modal-fullscreen-xxl-down .cx-consignment-tracking-container {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header, .modal-fullscreen-xxl-down cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .cx-cancel-replenishment-dialog-header, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .modal-fullscreen-xxl-down .cx-cancel-replenishment-dialog-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body, .modal-fullscreen-xxl-down cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .cx-cancel-replenishment-dialog-body, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .modal-fullscreen-xxl-down .cx-cancel-replenishment-dialog-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(31, 123, 192, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-header, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .cx-cancel-replenishment-dialog-header {
  padding: 1rem 1rem;
}
.modal-body, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .cx-cancel-replenishment-dialog-body {
  padding: 16px 30px 30px;
}
.modal-content, cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content, cx-tracking-events .cx-consignment-tracking-dialog .cx-consignment-tracking-container {
  border-radius: 0;
  border: none;
}
.modal .close {
  font-size: 38px;
  font-weight: 100;
  bottom: 5px;
  position: relative;
  margin-inline-start: 0;
  margin-inline-end: 0;
  align-self: flex-end;
}
@media (max-width: 767.98px) {
  .modal .close {
    margin-bottom: 0;
  }
}
.modal[aria-hidden=false] {
  display: block;
}

.cx-modal-container {
  display: flex;
  align-items: center;
  margin: auto;
  height: 100%;
  overflow-y: auto;
  max-width: 768px !important;
  min-width: 768px !important;
}
@media (max-width: 575.98px) {
  .cx-modal-container {
    margin: 0 !important;
    min-width: 100% !important;
    max-width: 100% !important;
    overflow-y: initial;
    height: 100%;
    overflow-y: auto;
  }
}
@media (min-width: 768px) {
  .cx-modal-container {
    max-width: 768px !important;
    min-width: 768px !important;
  }
}

.cx-modal-header {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  padding-inline-end: 1.875rem;
  padding-bottom: 0;
  padding-inline-start: 1.875rem;
}

.cx-modal-content {
  background-color: var(--cx-color-inverse);
  width: 100%;
}

.cx-modal-footer {
  padding: 0px 27px 30px;
}

@media (min-width: 992px) {
  .cx-asm-dialog {
    max-width: 95% !important;
    min-width: 95% !important;
  }
}
.cx-asm-dialog .cx-modal-content {
  border-radius: 16px;
}

.cx-agnostic-table table, cx-return-request-items table, cx-amend-order-items table {
  margin: 1rem 0;
  border-collapse: collapse;
  width: 100%;
}
.cx-agnostic-table th, cx-return-request-items th, cx-amend-order-items th,
.cx-agnostic-table td,
cx-return-request-items td,
cx-amend-order-items td {
  padding: 1.25rem 0.1rem 1.25rem 0.5rem;
  vertical-align: middle;
  text-align: center;
}
.cx-agnostic-table th:nth-of-type(1), cx-return-request-items th:nth-of-type(1), cx-amend-order-items th:nth-of-type(1),
.cx-agnostic-table td:nth-of-type(1),
cx-return-request-items td:nth-of-type(1),
cx-amend-order-items td:nth-of-type(1) {
  text-align: start;
}
.cx-agnostic-table th:nth-last-of-type(1), cx-return-request-items th:nth-last-of-type(1), cx-amend-order-items th:nth-last-of-type(1),
.cx-agnostic-table td:nth-last-of-type(1),
cx-return-request-items td:nth-last-of-type(1),
cx-amend-order-items td:nth-last-of-type(1) {
  text-align: end;
}
.cx-agnostic-table tr, cx-return-request-items tr, cx-amend-order-items tr {
  border-top: 1px solid var(--cx-color-medium);
  padding: 1.25rem 0;
}
.cx-agnostic-table th, cx-return-request-items th, cx-amend-order-items th {
  vertical-align: bottom;
  text-transform: capitalize;
  text-align: center;
  color: var(--cx-color-secondary);
  font-weight: var(--cx-font-weight-bold);
  font-size: var(--cx-font-size, 0.875rem);
  white-space: nowrap;
}
.cx-agnostic-table th:nth-last-of-type(1), cx-return-request-items th:nth-last-of-type(1), cx-amend-order-items th:nth-last-of-type(1) {
  padding-inline-end: 16px;
}
.cx-agnostic-table .cx-mobile-header, cx-return-request-items .cx-mobile-header, cx-amend-order-items .cx-mobile-header {
  display: none;
}
.cx-agnostic-table .cx-mobile-only, cx-return-request-items .cx-mobile-only, cx-amend-order-items .cx-mobile-only {
  display: none;
}
@media (max-width: 767.98px) {
  .cx-agnostic-table table, cx-return-request-items table, cx-amend-order-items table,
  .cx-agnostic-table tr,
  cx-return-request-items tr,
  cx-amend-order-items tr,
  .cx-agnostic-table td,
  cx-return-request-items td,
  cx-amend-order-items td {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
  }
  .cx-agnostic-table td, cx-return-request-items td, cx-amend-order-items td {
    align-items: center;
    padding: 0.313rem;
  }
  .cx-agnostic-table thead, cx-return-request-items thead, cx-amend-order-items thead {
    display: none;
  }
  .cx-agnostic-table .cx-mobile-header, cx-return-request-items .cx-mobile-header, cx-amend-order-items .cx-mobile-header {
    display: block;
    white-space: nowrap;
    text-align: start;
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.2222222222);
    overflow-wrap: break-word;
    padding-inline-end: 1rem;
    min-width: 5rem;
  }
  .cx-agnostic-table .cx-mobile-only, cx-return-request-items .cx-mobile-only, cx-amend-order-items .cx-mobile-only {
    display: flex;
  }
}

cx-return-request-items .cx-item-list-header, cx-amend-order-items .cx-item-list-header {
  border-bottom: none;
}
cx-return-request-items .cx-item-list-header .cx-item-list-qty, cx-amend-order-items .cx-item-list-header .cx-item-list-qty {
  text-transform: uppercase;
}
cx-return-request-items .cx-item-list-items tr:last-child, cx-amend-order-items .cx-item-list-items tr:last-child {
  border-bottom: 1px solid var(--cx-color-medium);
}
cx-return-request-items td.cx-actions cx-add-to-cart, cx-amend-order-items td.cx-actions cx-add-to-cart,
cx-return-request-items td.cx-actions button,
cx-amend-order-items td.cx-actions button {
  white-space: nowrap;
  display: block;
  margin-inline-start: auto;
}
@media (max-width: 767.98px) {
  cx-return-request-items td.cx-actions cx-add-to-cart, cx-amend-order-items td.cx-actions cx-add-to-cart,
  cx-return-request-items td.cx-actions button,
  cx-amend-order-items td.cx-actions button {
    margin-inline-start: auto;
  }
}
@media (max-width: 575.98px) {
  cx-return-request-items td.cx-actions cx-add-to-cart, cx-amend-order-items td.cx-actions cx-add-to-cart,
  cx-return-request-items td.cx-actions button,
  cx-amend-order-items td.cx-actions button {
    min-height: 48px;
  }
}
cx-return-request-items td.cx-actions .cx-remove-btn, cx-amend-order-items td.cx-actions .cx-remove-btn {
  margin-inline-start: auto;
}
@media (max-width: 767.98px) {
  cx-return-request-items td.cx-actions .cx-remove-btn, cx-amend-order-items td.cx-actions .cx-remove-btn {
    margin: 0;
  }
}
cx-return-request-items .cx-table-item-container, cx-amend-order-items .cx-table-item-container {
  display: inline-grid;
  grid-template-columns: 5rem 14rem;
  gap: 1rem;
  text-indent: 0;
}
cx-return-request-items .cx-name, cx-amend-order-items .cx-name {
  margin-top: 0.5rem;
  font-size: var(--cx-font-size, 1rem);
}
cx-return-request-items .cx-code, cx-amend-order-items .cx-code {
  color: var(--cx-color-secondary);
  margin-top: 0.5em;
}
cx-return-request-items .cx-link, cx-amend-order-items .cx-link {
  text-decoration: none;
  color: var(--cx-color-text);
  font-weight: var(--cx-font-weight-bold);
}
cx-return-request-items .cx-total, cx-amend-order-items .cx-total {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
}
cx-return-request-items .cx-cart-addons, cx-amend-order-items .cx-cart-addons {
  display: flex;
  width: 100%;
  flex-direction: column;
}
cx-return-request-items .cx-cart-addons cx-configurator-cart-entry-info, cx-amend-order-items .cx-cart-addons cx-configurator-cart-entry-info {
  margin-inline-start: 6rem;
}
cx-return-request-items .cx-cart-addons cx-cart-pickup-options-container, cx-amend-order-items .cx-cart-addons cx-cart-pickup-options-container {
  margin-inline-start: 6rem;
}
@media (max-width: 767.98px) {
  cx-return-request-items .cx-table-item-container, cx-amend-order-items .cx-table-item-container {
    grid-template-columns: 5em auto;
  }
  cx-return-request-items .cx-table-item-container .cx-price, cx-amend-order-items .cx-table-item-container .cx-price {
    display: none;
  }
  cx-return-request-items .cx-value cx-item-counter button[type=button], cx-amend-order-items .cx-value cx-item-counter button[type=button] {
    margin-bottom: 0em;
  }
  cx-return-request-items .cx-actions button, cx-amend-order-items .cx-actions button {
    margin-inline-start: 0;
    text-align: start;
  }
  cx-return-request-items td:nth-of-type(1), cx-amend-order-items td:nth-of-type(1) {
    padding-inline-start: 0;
  }
  cx-return-request-items td:not(:nth-of-type(1)), cx-amend-order-items td:not(:nth-of-type(1)) {
    font-size: var(--cx-font-size, 0.875rem);
    margin-inline-start: 5.7rem;
  }
}
@media (max-width: 767.98px) and (max-width: 575.98px) {
  cx-return-request-items .cx-quantity .cx-mobile-header, cx-amend-order-items .cx-quantity .cx-mobile-header {
    margin-bottom: 5px;
  }
}

cx-amend-order-actions {
  justify-content: center;
  padding: 1rem 0 0;
}
cx-amend-order-actions > * {
  margin: 0 0 1rem;
}

@media (max-width: 575.98px) {
  cx-order-history .cx-order-history {
    max-width: 100%;
    padding: 0;
  }
}
cx-order-history .cx-order-history-table {
  padding: 1.5rem 0 1.125rem 0;
  margin-bottom: 0;
  border-top: 1px solid var(--cx-color-light);
  border-bottom: 1px solid var(--cx-color-light);
}
cx-order-history .cx-order-history-table th {
  padding: 1.5rem 0 1.125rem 0;
  text-align: start;
}
cx-order-history .cx-order-history-table th:last-child {
  text-align: end;
}
cx-order-history .cx-order-history-table td {
  width: 25%;
  padding: 1.625rem 0;
}
@media (min-width: 768px) {
  cx-order-history .cx-order-history-table td {
    text-align: start;
  }
  cx-order-history .cx-order-history-table td:last-child {
    text-align: end;
  }
}
@media (max-width: 575.98px) {
  cx-order-history .cx-order-history-table td {
    width: 100%;
    display: flex;
    border-width: 0;
    padding: 0 1.25rem;
  }
  cx-order-history .cx-order-history-table td:first-child {
    padding-top: 1.25rem;
  }
  cx-order-history .cx-order-history-table td:last-child {
    padding-bottom: 1.25rem;
  }
}
cx-order-history .cx-order-history-table.cx-order-history-table-po td {
  width: 20%;
}
@media (max-width: 575.98px) {
  cx-order-history .cx-order-history-table.cx-order-history-table-po td {
    width: 100%;
  }
}
cx-order-history .cx-order-history-header {
  padding: 40px 0 0 0;
  color: var(--cx-color-text);
}
@media (max-width: 575.98px) {
  cx-order-history .cx-order-history-header {
    padding-top: 40px;
    padding-inline-end: 20px;
    padding-bottom: 0;
    padding-inline-start: 20px;
  }
}
@media (max-width: 575.98px) {
  cx-order-history .cx-order-history-thead-mobile {
    display: none;
  }
}
cx-order-history .cx-order-history-code .cx-order-history-value {
  color: var(--cx-color-primary);
  text-decoration: underline;
}
cx-order-history .cx-order-history-po {
  text-align: center;
}
@media (max-width: 575.98px) {
  cx-order-history .cx-order-history-po {
    text-align: start;
  }
}
cx-order-history .cx-order-history-cost-center {
  text-align: center;
}
@media (max-width: 575.98px) {
  cx-order-history .cx-order-history-cost-center {
    text-align: start;
  }
}
cx-order-history .cx-order-history-placed {
  text-align: center;
}
@media (max-width: 575.98px) {
  cx-order-history .cx-order-history-placed {
    text-align: start;
  }
}
cx-order-history .cx-order-history-status {
  text-align: center;
}
@media (max-width: 575.98px) {
  cx-order-history .cx-order-history-status {
    text-align: start;
  }
}
cx-order-history .cx-order-history-total {
  text-align: end;
}
@media (max-width: 575.98px) {
  cx-order-history .cx-order-history-total {
    text-align: start;
  }
}
cx-order-history .cx-order-history-label {
  text-transform: uppercase;
  color: var(--cx-color-secondary);
}
@media (min-width: 768px) {
  cx-order-history .cx-order-history-label {
    display: none;
  }
}
@media (max-width: 575.98px) {
  cx-order-history .cx-order-history-label {
    font-size: var(--cx-font-size, 0.875rem);
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.2222222222);
    min-width: 110px;
  }
}
cx-order-history .cx-order-history-value {
  color: var(--cx-color-text);
}
@media (max-width: 575.98px) {
  cx-order-history .cx-order-history-value {
    font-size: var(--cx-font-size, 1rem);
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.2222222222);
    font-weight: "normal";
  }
}
cx-order-history .cx-order-history-form-group {
  padding: 0;
  margin-bottom: 0;
  align-items: center;
  display: flex;
  width: 40%;
}
cx-order-history .cx-order-history-form-group span {
  margin-inline-end: 0.5rem;
  flex: none;
}
cx-order-history .cx-order-history-form-group cx-sorting {
  width: 100%;
}
@media (max-width: 575.98px) {
  cx-order-history .cx-order-history-form-group {
    padding: 1.25rem;
    width: 100%;
  }
}
cx-order-history .cx-order-history-sort.top {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  margin: 0;
}
@media (max-width: 575.98px) {
  cx-order-history .cx-order-history-sort.top {
    flex-direction: column;
    padding-top: 0;
  }
}
cx-order-history .cx-order-history-sort.bottom {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0 1rem 0;
  margin: 0;
}
@media (max-width: 575.98px) {
  cx-order-history .cx-order-history-sort.bottom {
    flex-direction: column;
  }
}
@media (max-width: 575.98px) {
  cx-order-history .cx-order-history-pagination {
    margin: 0 auto;
  }
}
cx-order-history .cx-order-history-no-order {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: "normal";
  min-height: 415px;
}
@media (max-width: 575.98px) {
  cx-order-history .cx-order-history-no-order {
    min-height: 474px;
    padding-inline-start: 1.25rem;
    padding-inline-end: 1.25rem;
  }
}
cx-order-history .cx-order-history-no-order .btn {
  margin: 1.25rem 0;
}
cx-order-history .cx-replenishment-details-order-history-header {
  margin-top: 30px;
  padding: 20px;
  background-color: var(--cx-color-background);
}
cx-order-history .cx-replenishment-details-order-history-header h4 {
  margin-bottom: 0;
  font-weight: bold;
}
cx-order-history .cx-replenishment-details-order-history-no-order {
  display: flex;
  justify-content: center;
  padding: 40px 0 0 0;
  min-height: 0;
}

@media (max-width: 575.98px) {
  cx-replenishment-order-history .cx-replenishment-order-history {
    max-width: 100%;
    padding: 0;
  }
}
cx-replenishment-order-history .cx-replenishment-order-history-table {
  padding: 1.5rem 0 1.125rem 0;
  margin-bottom: 0;
  border-width: 1px 0 1px 0;
  border-color: var(--cx-color-light);
  border-style: solid;
}
cx-replenishment-order-history .cx-replenishment-order-history-table tr {
  width: 100%;
}
cx-replenishment-order-history .cx-replenishment-order-history-table tr:hover {
  background-color: var(--cx-color-background);
}
@media (max-width: 575.98px) {
  cx-replenishment-order-history .cx-replenishment-order-history-table tr {
    border-width: 1px 0 0 0;
    border-color: var(--cx-color-light);
    border-style: solid;
  }
  cx-replenishment-order-history .cx-replenishment-order-history-table tr:first-child {
    border-width: 0;
    padding: 1.25rem 0 0 0;
  }
}
cx-replenishment-order-history .cx-replenishment-order-history-table th {
  padding: 1.5rem 0 1.125rem 0;
  padding-top: 1.5rem;
  padding-inline-end: 0;
  padding-bottom: 1.125rem;
  padding-inline-start: 5px;
  text-align: start;
  vertical-align: top;
}
cx-replenishment-order-history .cx-replenishment-order-history-table th:first-child {
  padding-inline-start: 0;
}
cx-replenishment-order-history .cx-replenishment-order-history-table th:last-child {
  text-align: end;
}
cx-replenishment-order-history .cx-replenishment-order-history-table td {
  padding: 1.625rem 5px;
}
@media (min-width: 768px) {
  cx-replenishment-order-history .cx-replenishment-order-history-table td {
    text-align: start;
  }
  cx-replenishment-order-history .cx-replenishment-order-history-table td:first-child {
    padding-inline-start: 0px;
  }
  cx-replenishment-order-history .cx-replenishment-order-history-table td:last-child {
    text-align: end;
  }
}
@media (max-width: 575.98px) {
  cx-replenishment-order-history .cx-replenishment-order-history-table td {
    width: 100%;
    display: flex;
    border-width: 0;
    padding: 0 1.25rem;
    margin-top: 0.5rem;
  }
  cx-replenishment-order-history .cx-replenishment-order-history-table td:first-child {
    padding-top: 1.25rem;
  }
  cx-replenishment-order-history .cx-replenishment-order-history-table td:last-child {
    padding-bottom: 1.25rem;
    padding-top: 7px;
  }
}
cx-replenishment-order-history .cx-replenishment-order-history-table .cx-replenishment-order-history-code .cx-replenishment-order-history-value {
  text-decoration: underline;
  color: var(--cx-color-primary);
}
cx-replenishment-order-history .cx-replenishment-order-history-table .cx-replenishment-order-history-frequency {
  width: 25%;
}
@media (max-width: 575.98px) {
  cx-replenishment-order-history .cx-replenishment-order-history-table .cx-replenishment-order-history-frequency {
    width: unset;
  }
}
cx-replenishment-order-history .cx-replenishment-order-history-table .cx-replenishment-order-history-total {
  text-align: end;
}
@media (max-width: 575.98px) {
  cx-replenishment-order-history .cx-replenishment-order-history-table .cx-replenishment-order-history-total {
    text-align: start;
  }
}
cx-replenishment-order-history .cx-replenishment-order-history-table .cx-replenishment-order-history-cancel {
  width: 15%;
}
cx-replenishment-order-history .cx-replenishment-order-history-table .cx-replenishment-order-history-cancel .cx-order-cancel {
  font-size: var(--cx-font-size, 0.875rem);
}
@media (max-width: 575.98px) {
  cx-replenishment-order-history .cx-replenishment-order-history-table .cx-replenishment-order-history-cancel {
    width: unset;
  }
}
cx-replenishment-order-history .cx-replenishment-order-history-table .cx-replenishment-order-history-ellipses {
  display: inline-block;
  max-width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
cx-replenishment-order-history .cx-replenishment-order-history-header {
  padding: 40px 0 0 0;
  color: var(--cx-color-text);
}
@media (max-width: 575.98px) {
  cx-replenishment-order-history .cx-replenishment-order-history-header {
    padding: 40px 20px 0 20px;
  }
}
@media (max-width: 575.98px) {
  cx-replenishment-order-history .cx-replenishment-order-history-thead-mobile {
    display: none;
  }
}
cx-replenishment-order-history .cx-replenishment-order-history-label {
  text-transform: uppercase;
  color: var(--cx-color-secondary);
}
@media (max-width: 575.98px) {
  cx-replenishment-order-history .cx-replenishment-order-history-label {
    font-size: var(--cx-font-size, 0.875rem);
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.2222222222);
    min-width: 150px;
    margin-block-end: 10px;
  }
}
cx-replenishment-order-history .cx-replenishment-order-history-value {
  color: var(--cx-color-text);
}
@media (max-width: 575.98px) {
  cx-replenishment-order-history .cx-replenishment-order-history-value {
    font-size: var(--cx-font-size, 1rem);
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.2222222222);
    font-weight: "normal";
  }
}
cx-replenishment-order-history .cx-replenishment-order-history-form-group {
  padding: 0;
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  cx-replenishment-order-history .cx-replenishment-order-history-form-group {
    padding: 1.25rem;
  }
}
cx-replenishment-order-history .cx-replenishment-order-history-sort.top {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  margin: 0;
}
@media (max-width: 575.98px) {
  cx-replenishment-order-history .cx-replenishment-order-history-sort.top {
    flex-direction: column;
    padding-top: 0;
  }
}
cx-replenishment-order-history .cx-replenishment-order-history-sort.top .cx-replenishment-order-history-form-group {
  align-items: center;
  display: flex;
}
cx-replenishment-order-history .cx-replenishment-order-history-sort.top .cx-replenishment-order-history-form-group span {
  margin-inline-end: 0.5rem;
  flex: none;
}
cx-replenishment-order-history .cx-replenishment-order-history-sort.top .cx-replenishment-order-history-form-group cx-sorting {
  width: 100%;
}
cx-replenishment-order-history .cx-replenishment-order-history-sort.bottom {
  display: flex;
  justify-content: flex-end;
  padding: 2rem 0 1rem 0;
  margin: 0;
}
@media (max-width: 575.98px) {
  cx-replenishment-order-history .cx-replenishment-order-history-sort.bottom {
    flex-direction: column;
    padding-top: 1.25rem;
  }
}
@media (max-width: 575.98px) {
  cx-replenishment-order-history .cx-replenishment-order-history-pagination {
    margin: 0 auto;
  }
}
cx-replenishment-order-history .cx-replenishment-order-history-no-order {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: "normal";
  min-height: 415px;
}
@media (max-width: 575.98px) {
  cx-replenishment-order-history .cx-replenishment-order-history-no-order {
    min-height: 474px;
    padding-inline-start: 1.25rem;
    padding-inline-end: 1.25rem;
  }
}
cx-replenishment-order-history .cx-replenishment-order-history-no-order .btn {
  margin: 1.25rem 0;
}

@media (max-width: 575.98px) {
  cx-order-return-request-list .cx-order-history {
    max-width: 100%;
    padding: 0;
  }
}
cx-order-return-request-list .cx-order-history-table {
  padding: 1.5rem 0 1.125rem 0;
  margin-bottom: 0;
  border-width: 1px 0 1px 0;
  border-color: var(--cx-color-light);
  border-style: solid;
}
cx-order-return-request-list .cx-order-history-table tr {
  width: 100%;
}
@media (max-width: 575.98px) {
  cx-order-return-request-list .cx-order-history-table tr {
    border-width: 1px 0 0 0;
    border-color: var(--cx-color-light);
    border-style: solid;
  }
  cx-order-return-request-list .cx-order-history-table tr:first-child {
    border-width: 0;
    padding: 1.25rem 0 0 0;
  }
}
cx-order-return-request-list .cx-order-history-table th {
  padding: 1.5rem 0 1.125rem 0;
  text-align: start;
}
cx-order-return-request-list .cx-order-history-table th:last-child {
  text-align: end;
}
cx-order-return-request-list .cx-order-history-table td {
  width: 25%;
  padding: 1.625rem 0;
}
@media (min-width: 768px) {
  cx-order-return-request-list .cx-order-history-table td {
    text-align: start;
  }
  cx-order-return-request-list .cx-order-history-table td:last-child {
    text-align: end;
  }
}
@media (max-width: 575.98px) {
  cx-order-return-request-list .cx-order-history-table td {
    width: 100%;
    display: flex;
    border-width: 0;
    padding: 0 1.25rem;
  }
  cx-order-return-request-list .cx-order-history-table td:first-child {
    padding-top: 1.25rem;
  }
  cx-order-return-request-list .cx-order-history-table td:last-child {
    padding-bottom: 1.25rem;
  }
}
cx-order-return-request-list .cx-order-history-header {
  padding: 40px 0 0 0;
  color: var(--cx-color-text);
}
@media (max-width: 575.98px) {
  cx-order-return-request-list .cx-order-history-header {
    padding: 40px 20px 0 20px;
  }
}
@media (max-width: 575.98px) {
  cx-order-return-request-list .cx-order-history-thead-mobile {
    display: none;
  }
}
cx-order-return-request-list .cx-order-history-code {
  text-decoration: underline;
}
@media (max-width: 575.98px) {
  cx-order-return-request-list .cx-order-history-code {
    text-decoration: none;
  }
}
cx-order-return-request-list .cx-order-history-placed,
cx-order-return-request-list .cx-order-history-status {
  text-align: center;
}
cx-order-return-request-list .cx-order-history-total {
  text-align: end;
}
@media (max-width: 575.98px) {
  cx-order-return-request-list .cx-order-history-placed,
  cx-order-return-request-list .cx-order-history-status,
  cx-order-return-request-list .cx-order-history-total {
    text-align: start;
  }
}
cx-order-return-request-list .cx-order-history-label {
  text-transform: uppercase;
  color: var(--cx-color-secondary);
}
@media (min-width: 768px) {
  cx-order-return-request-list .cx-order-history-label {
    display: none;
  }
}
@media (max-width: 575.98px) {
  cx-order-return-request-list .cx-order-history-label {
    font-size: var(--cx-font-size, 0.875rem);
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.2222222222);
    min-width: 110px;
  }
}
cx-order-return-request-list .cx-order-history-value {
  color: var(--cx-color-text);
}
@media (max-width: 575.98px) {
  cx-order-return-request-list .cx-order-history-value {
    font-size: var(--cx-font-size, 1rem);
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.2222222222);
    text-decoration: underline;
  }
}
cx-order-return-request-list .cx-order-history-form-group {
  padding: 0;
  margin-bottom: 0;
  align-items: center;
  display: flex;
  width: 40%;
}
cx-order-return-request-list .cx-order-history-form-group span {
  margin-inline-end: 0.5rem;
  flex: none;
}
cx-order-return-request-list .cx-order-history-form-group cx-sorting {
  width: 100%;
}
@media (max-width: 575.98px) {
  cx-order-return-request-list .cx-order-history-form-group {
    padding: 1.25rem;
    width: 100%;
  }
}
cx-order-return-request-list .cx-order-history-sort.top {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  margin: 0;
}
@media (max-width: 575.98px) {
  cx-order-return-request-list .cx-order-history-sort.top {
    flex-direction: column;
    padding-top: 0;
  }
}
cx-order-return-request-list .cx-order-history-sort.bottom {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0 1rem 0;
  margin: 0;
}
@media (max-width: 575.98px) {
  cx-order-return-request-list .cx-order-history-sort.bottom {
    flex-direction: column;
    padding-top: 0;
  }
}
@media (max-width: 575.98px) {
  cx-order-return-request-list .cx-order-history-pagination {
    margin: 0 auto;
  }
}
cx-order-return-request-list .cx-order-history-no-order {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  min-height: 415px;
}
@media (max-width: 575.98px) {
  cx-order-return-request-list .cx-order-history-no-order {
    min-height: 474px;
    padding-inline-start: 1.25rem;
    padding-inline-end: 1.25rem;
  }
}
cx-order-return-request-list .cx-order-history-no-order .btn {
  margin: 1.25rem 0;
}

cx-return-request-overview .cx-nav {
  justify-content: center;
  padding: 1rem 0 2rem;
}
cx-return-request-overview .cx-nav div:empty {
  display: none;
}
@media (max-width: 575.98px) {
  cx-return-request-overview .cx-nav .btn-secondary {
    margin: 0 0 1rem;
  }
}
cx-return-request-overview .cx-header {
  padding: 1.875rem 0;
  margin: 0 0 1.875rem;
  border-width: 1px;
  border-style: solid;
  border-color: var(--cx-color-light);
}
@media (max-width: 575.98px) {
  cx-return-request-overview .cx-header {
    border: 0 none;
    margin: 0;
  }
}
cx-return-request-overview .cx-detail {
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: var(--cx-color-light);
  padding-inline-start: 1.875rem;
}
cx-return-request-overview .cx-detail:last-child {
  border: 0 none;
}
@media (max-width: 575.98px) {
  cx-return-request-overview .cx-detail {
    padding-bottom: 0.625rem;
    padding-inline-start: 0.3125rem;
    border-inline-end: 0 none;
  }
}
cx-return-request-overview .cx-detail-label {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-return-request-overview .cx-detail-value {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: 400;
}

cx-return-request-totals {
  padding-top: 2rem;
  padding-inline-end: 2.5rem;
  padding-bottom: 0;
  padding-inline-start: 2.5rem;
}
cx-return-request-totals h4 {
  text-transform: uppercase;
  font-weight: var(--cx-font-weight-semi);
}
cx-return-request-totals .cx-summary-label {
  text-align: start;
  padding: 0;
}
cx-return-request-totals .cx-summary-amount {
  text-align: end;
  padding: 0;
}
cx-return-request-totals .cx-summary-row {
  padding: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
}
cx-return-request-totals .cx-summary-savings {
  color: var(--cx-color-success);
}
cx-return-request-totals .cx-summary-total {
  font-weight: var(--cx-font-weight-bold);
}
cx-return-request-totals .cx-coupon-code-pill {
  background: var(--cx-g-color-light);
  padding-top: 7px;
  padding-inline-end: 15px;
  padding-bottom: 7px;
  padding-inline-start: 13px;
  margin-top: 10px;
  margin-inline-end: 10px;
  margin-bottom: 5px;
  margin-inline-start: 0;
  display: inline-flex;
  align-items: flex-start;
  border-radius: 4px;
  word-break: break-all;
}
cx-return-request-totals .cx-coupon-code--pill-value {
  line-height: 1.7;
}
cx-return-request-totals .cx-footnote {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
}

cx-cancel-order cx-form-errors p {
  margin: 1rem auto;
}

cx-consignment-tracking {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 0.5rem;
}

cx-tracking-events .cx-consignment-tracking-dialog .cx-consignment-tracking-container .cx-consignment-tracking-title {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}
@media (max-width: 575.98px) {
  cx-tracking-events .cx-consignment-tracking-dialog .cx-consignment-tracking-container .body {
    padding: 0;
  }
}
cx-tracking-events .cx-consignment-tracking-dialog .cx-consignment-tracking-container .cx-shipment-heading {
  position: relative;
  padding-top: 1.5rem;
  padding-inline-end: 0;
  padding-bottom: 0.4rem;
  padding-inline-start: 2rem;
  background-color: var(--cx-color-background);
}
cx-tracking-events .cx-consignment-tracking-dialog .cx-consignment-tracking-container .cx-no-tracking-heading {
  position: relative;
  padding: 2rem;
}
cx-tracking-events .cx-consignment-tracking-dialog .cx-consignment-tracking-container .cx-shipment-content {
  padding: 0.6rem 0 1.2rem 0;
}
cx-tracking-events .cx-consignment-tracking-dialog .cx-consignment-tracking-container .cx-shipment-title {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-tracking-events .cx-consignment-tracking-dialog .cx-consignment-tracking-container .cx-tracking-events .cx-tracking-event-body {
  border-inline-start: 0.7rem solid var(--cx-color-secondary);
  padding-inline-start: 1rem;
  margin-bottom: 1rem;
}
cx-tracking-events .cx-consignment-tracking-dialog .cx-consignment-tracking-container .cx-tracking-events .cx-tracking-event-body div {
  padding-bottom: 0.6rem;
}
cx-tracking-events .cx-consignment-tracking-dialog .cx-consignment-tracking-container .cx-tracking-events .cx-tracking-event-body .cx-tracking-event-title {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-tracking-events .cx-consignment-tracking-dialog .cx-consignment-tracking-container .cx-tracking-events .cx-tracking-event-body .cx-tracking-event-city {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-tracking-events .cx-consignment-tracking-dialog .cx-consignment-tracking-container .cx-tracking-events .cx-tracking-event-body .cx-tracking-event-content {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}

cx-my-account-v2-order-history .cx-my-account-v2-order-history-header {
  display: block;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: var(--cx-color-light);
  margin: 0 0 25px 0;
  margin: 0 0 25px 0;
}
cx-my-account-v2-order-history .cx-my-account-v2-order-history-body .cx-order-history-pagination {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0 1rem 0;
  margin: 0;
}
@media (max-width: 575.98px) {
  cx-my-account-v2-order-history .cx-my-account-v2-order-history-body .cx-order-history-pagination {
    flex-direction: column;
  }
}
@media (max-width: 575.98px) {
  cx-my-account-v2-order-history .cx-my-account-v2-order-history-body .cx-order-history-pagination {
    margin: 0 auto;
  }
}
cx-my-account-v2-order-history .cx-my-account-v2-order-history-body .cx-each-order {
  display: block;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: var(--cx-color-light);
  margin: 0 0 25px 0;
}
cx-my-account-v2-order-history .cx-my-account-v2-order-history-body .cx-each-order:last-of-type {
  border: none;
}
cx-my-account-v2-order-history .cx-my-account-v2-order-history-body .cx-my-account-v2-order-history-code {
  color: var(--cx-color-primary);
  text-decoration: underline;
  font-size: 22px;
  font-weight: 500;
  margin: 0 0 16px 0;
}
cx-my-account-v2-order-history .cx-my-account-v2-order-history-body .cx-my-account-v2-order-summary {
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 15px 0;
}
cx-my-account-v2-order-history .cx-my-account-v2-order-history-body .cx-consolidated-order-info {
  display: flex;
  margin: 16px 0 0 0;
}
cx-my-account-v2-order-history .cx-my-account-v2-order-history-body .cx-consolidated-order-info:last-of-type {
  margin: 0 0 20px 0;
}
cx-my-account-v2-order-history .cx-my-account-v2-order-history-body .cx-consolidated-order-info .cx-order-info {
  width: 60%;
}
cx-my-account-v2-order-history .cx-my-account-v2-order-history-body .cx-consolidated-order-info .cx-order-info .cx-consignment-info {
  font-size: 16px;
  color: var(--cx-color-secondary);
}
cx-my-account-v2-order-history .cx-my-account-v2-order-history-body .cx-consolidated-order-info .cx-order-info .cx-consignment-info .cx-tracking-id {
  text-decoration: underline;
  font-weight: var(--cx-font-weight-semi);
  color: var(--cx-color-secondary);
}
@media (max-width: 575.98px) {
  cx-my-account-v2-order-history .cx-my-account-v2-order-history-body .cx-consolidated-order-info .cx-order-info .cx-list-header {
    padding: 0;
  }
}
cx-my-account-v2-order-history .cx-my-account-v2-order-history-body .cx-consolidated-order-info .cx-order-info .cx-list-header .cx-list-status {
  padding: 5px 30px;
  background-color: var(--cx-color-background);
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: bold;
}
cx-my-account-v2-order-history .cx-my-account-v2-order-history-body .cx-consolidated-order-info .cx-order-info .cx-order-status {
  margin: 16px 0 0 0;
  font-size: 18px;
}
cx-my-account-v2-order-history .cx-my-account-v2-order-history-body .cx-consolidated-order-info .cx-order-info .cx-order-status-critical {
  margin: 16px 0 0 0;
  font-size: 18px;
  color: var(--cx-color-alert, var(--cx-color-danger));
}
cx-my-account-v2-order-history .cx-my-account-v2-order-history-body .cx-consolidated-order-info .cx-order-info .cx-order-status-critical-link {
  font-size: 18px;
  color: var(--cx-color-alert, var(--cx-color-danger));
  text-decoration: underline;
}
cx-my-account-v2-order-history .cx-my-account-v2-order-history-body .cx-consolidated-order-info .cx-order-images-container {
  width: 40%;
}
cx-my-account-v2-order-history .cx-my-account-v2-order-history-body .cx-consolidated-order-info .cx-order-images-container .cx-order-img {
  width: 88px;
  height: 88px;
  margin: 0 8px 0px 0;
  display: inline-end;
  float: right;
}

cx-order-overview .cx-order-summary {
  background-color: var(--cx-color-background);
}
@media (max-width: 575.98px) {
  cx-order-overview .cx-order-summary {
    background-color: var(--cx-color-inverse);
  }
}
cx-order-overview .cx-order-summary .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 36px 32px;
  margin: 0;
  min-width: 100%;
}
@media (max-width: 767.98px) {
  cx-order-overview .cx-order-summary .container {
    background-color: var(--cx-color-inverse);
  }
}
cx-order-overview .cx-order-summary .container .cx-order-details-cards {
  flex-grow: 1;
}
@media (min-width: 992px) {
  cx-order-overview .cx-order-summary .container .cx-order-details-cards cx-card {
    padding: 10px 0;
    display: block;
  }
  cx-order-overview .cx-order-summary .container .cx-order-details-cards cx-card:first-child {
    padding-top: 0;
  }
  cx-order-overview .cx-order-summary .container .cx-order-details-cards cx-card:last-child {
    padding-bottom: 0;
  }
  cx-order-overview .cx-order-summary .container .cx-order-details-cards cx-card .cx-card-body {
    padding: 0;
  }
}
@media (max-width: 767.98px) {
  cx-order-overview .cx-order-summary .container .cx-order-details-cards {
    border: 1px solid var(--cx-color-light);
    background-color: var(--cx-color-inverse);
    margin-inline-end: 15px;
    border-radius: 10px;
  }
}
@media (max-width: 575.98px) {
  cx-order-overview .cx-order-summary .container .cx-order-details-cards {
    margin-inline-end: 0;
    margin-bottom: 30px;
  }
}
cx-order-overview .cx-order-summary .container .cx-order-details-cards .cx-card-title {
  font-weight: var(--cx-font-weight-semi);
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  cx-order-overview .cx-order-summary .container {
    max-width: 100%;
    min-width: 100%;
    padding: 1.25rem 0;
  }
}
@media (max-width: 575.98px) {
  cx-order-overview .cx-order-summary .container {
    flex-direction: column;
  }
}
cx-order-overview .cx-order-summary .container .cx-summary-card {
  flex: 1;
  padding: 0 15px;
}
@media (min-width: 992px) {
  cx-order-overview .cx-order-summary .container .cx-summary-card:not(:last-of-type) {
    border-inline-end: 1px solid var(--cx-color-text);
  }
}
@media (max-width: 767.98px) {
  cx-order-overview .cx-order-summary .container .cx-summary-card {
    flex: 0 0 33%;
  }
}
@media (max-width: 575.98px) {
  cx-order-overview .cx-order-summary .container .cx-summary-card {
    flex: 1;
    background-color: var(--cx-color-inverse);
    border-width: 1px;
    border-style: solid;
    border-color: var(--cx-color-light);
    margin: 0.625rem 0;
  }
}
cx-order-overview .cx-order-summary .container .cx-summary-card .cx-card-title {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: var(--cx-font-weight-bold);
  margin-bottom: 0.5rem;
}
cx-order-overview cx-order-detail-billing {
  flex-grow: 1;
  margin-bottom: 0;
}
@media (min-width: 992px) {
  cx-order-overview cx-order-detail-billing {
    border-inline-start: 1px solid var(--cx-color-text);
    padding-inline-start: 10rem;
  }
}
cx-order-overview cx-order-detail-billing .cx-review-summary {
  flex-direction: column;
}
@media (max-width: 767.98px) {
  cx-order-overview cx-order-detail-billing .cx-review-summary {
    border: 1px solid var(--cx-color-light);
    background-color: var(--cx-color-inverse);
    border-radius: 10px;
  }
}
cx-order-overview cx-order-detail-billing .cx-review-summary-card {
  width: 100%;
}
@media (min-width: 992px) {
  cx-order-overview cx-order-detail-billing .cx-review-summary-card {
    padding: 10px 0;
    display: block;
  }
  cx-order-overview cx-order-detail-billing .cx-review-summary-card:first-child {
    padding-top: 0;
  }
  cx-order-overview cx-order-detail-billing .cx-review-summary-card:last-child {
    padding-bottom: 0;
  }
  cx-order-overview cx-order-detail-billing .cx-review-summary-card .cx-card-body {
    padding: 0;
  }
}
cx-order-overview cx-order-detail-billing .cx-review-summary-card .cx-card-title {
  font-size: var(--cx-font-size, 1.125rem);
  margin-bottom: 0;
}

cx-order-details-items {
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  cx-order-details-items {
    margin-top: 30px;
  }
}
@media (min-width: 768px) {
  cx-order-details-items {
    margin-top: 10px;
  }
}
@media (max-width: 575.98px) {
  cx-order-details-items .cx-list-header {
    padding: 0;
  }
}
@media (max-width: 575.98px) {
  cx-order-details-items .cx-list {
    margin-inline-end: 0;
    margin-inline-start: 0;
  }
}
cx-order-details-items .cx-list-status {
  margin: 0 0 0.5rem;
  padding: 15px 30px;
  background-color: var(--cx-color-background);
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: bold;
}
cx-order-details-items .cx-list-date {
  margin: 0.5rem 0 0;
  padding: 0 30px;
}
cx-order-details-items .cx-review-header {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-semi);
  padding-inline-start: 30px;
}
cx-order-details-items .cx-pickup-order-consigned-entries-header {
  background-color: var(--cx-color-background);
  font-weight: var(--cx-font-weight-semi);
  font-size: var(--cx-font-size, 1.125rem);
  padding: 15px 40px;
  margin: 0px 0 30px 0;
}

cx-order-overview .cx-account-summary {
  background-color: var(--cx-color-background);
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: var(--cx-color-light);
}
@media (max-width: 575.98px) {
  cx-order-overview .cx-account-summary {
    background-color: var(--cx-color-inverse);
  }
}
cx-order-overview .cx-account-summary .container {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0;
}
@media (max-width: 767.98px) {
  cx-order-overview .cx-account-summary .container {
    max-width: 100%;
    min-width: 100%;
    padding: 0 1.25rem;
  }
}
@media (max-width: 575.98px) {
  cx-order-overview .cx-account-summary .container {
    flex-direction: column;
    padding: 1.25rem;
  }
}
cx-order-overview .cx-account-summary .container .cx-summary-card {
  flex: 1;
  padding: 0 15px;
}
@media (min-width: 992px) {
  cx-order-overview .cx-account-summary .container .cx-summary-card:not(:last-of-type) {
    border-inline-end: 1px solid var(--cx-color-text);
  }
}
@media (max-width: 767.98px) {
  cx-order-overview .cx-account-summary .container .cx-summary-card {
    flex: 0 0 33%;
  }
}
@media (max-width: 575.98px) {
  cx-order-overview .cx-account-summary .container .cx-summary-card {
    flex: 1;
    background-color: var(--cx-color-inverse);
    border-width: 1px;
    border-style: solid;
    border-color: var(--cx-color-light);
    margin: 0.625rem 0;
  }
}
cx-order-overview .cx-account-summary .container .cx-summary-card .cx-card-title {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: bold;
  margin-bottom: 0.5rem;
}

cx-order-details-actions .cx-nav {
  justify-content: center;
  padding: 1rem 0 2rem;
}
cx-order-details-actions .cx-nav div:empty {
  display: none;
}
@media (max-width: 767.98px) {
  cx-order-details-actions .cx-nav .btn-secondary {
    margin: 0 0 1rem;
  }
}

cx-order-details-reorder .cx-nav {
  justify-content: center;
}
cx-order-details-reorder .cx-nav button {
  width: 100%;
}

cx-order-detail-billing {
  margin-bottom: 2.5rem;
}
cx-order-detail-billing .cx-review-summary {
  display: flex;
  flex-direction: row;
  background-color: var(--cx-color-background);
}
@media (max-width: 575.98px) {
  cx-order-detail-billing .cx-review-summary {
    flex-direction: column;
  }
}
cx-order-detail-billing .cx-review-summary-card {
  width: 50%;
}
@media (max-width: 575.98px) {
  cx-order-detail-billing .cx-review-summary-card {
    width: 100%;
  }
}
cx-order-detail-billing .cx-review-summary-card .cx-card-title {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}

cx-reorder-dialog .cx-modal-container .cx-modal-content .cx-dialog-title {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-reorder-dialog .cx-modal-container .cx-modal-content .cx-dialog-body .cx-reorder-dialog-areyousure-section {
  display: flex;
  flex-direction: column;
}
cx-reorder-dialog .cx-modal-container .cx-modal-content .cx-dialog-body p .cx-cart-mod-entry-container {
  display: block;
}
cx-reorder-dialog .cx-modal-container .cx-modal-content .cx-dialog-body p .cx-cart-mod-entry-container .alert-icon {
  vertical-align: initial;
}
cx-reorder-dialog .cx-modal-container .cx-modal-content .cx-dialog-body p .cx-cart-mod-entry-container .alert-icon cx-icon {
  padding-inline-end: 5px;
}
cx-reorder-dialog .cx-modal-container .cx-modal-content .cx-dialog-body p .cx-cart-mod-entry-container .alert-icon cx-icon.success {
  color: var(--cx-color-success);
}
cx-reorder-dialog .cx-modal-container .cx-modal-content .cx-dialog-body p .cx-cart-mod-entry-container .alert-icon cx-icon.warning {
  color: var(--cx-color-warning);
}
cx-reorder-dialog .cx-modal-container .cx-modal-content .cx-dialog-body p .cx-cart-mod-entry-container .alert-icon cx-icon.error {
  color: var(--cx-color-danger);
}
cx-reorder-dialog .cx-modal-container .cx-modal-content .cx-reorder-dialog-footer {
  justify-content: center;
  display: flex;
}
cx-reorder-dialog .cx-modal-container .cx-modal-content .cx-reorder-dialog-footer button {
  flex: 0 0 calc(50% - 0.5rem);
}
cx-reorder-dialog .cx-modal-container .cx-modal-content .cx-reorder-dialog-footer button:first-child {
  margin-inline-end: 0.5rem;
}
cx-reorder-dialog .cx-modal-container .cx-modal-content .cx-reorder-dialog-footer button:last-child {
  margin-inline-start: 0.5rem;
}
cx-reorder-dialog .cx-modal-container .cx-modal-content .cx-reorder-dialog-footer button:focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}

cx-my-account-v2-order-details-actions .cx-order-details-actions {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 1rem 1.5rem 1rem;
}
cx-my-account-v2-order-details-actions .cx-order-details-actions .cx-action-button {
  float: right;
  margin: 0 0 0 10px;
}
cx-my-account-v2-order-details-actions .cx-order-details-actions .cx-action-button-left {
  float: left;
  margin: 0 10px 0 0;
}

cx-my-account-v2-consignment-tracking .cx-list-status {
  padding: 15px 30px 30px 30px;
  display: flex;
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: bold;
  justify-content: space-between;
}
cx-my-account-v2-consignment-tracking .cx-list-status .cx-item-list-tracking-id {
  display: inline;
  color: var(--cx-color-secondary);
  font-weight: var(--cx-font-weight-semi);
  padding: 0 0px 0px 15px;
}
cx-my-account-v2-consignment-tracking .cx-list-status .cx-item-list-tracking-id .cx-tracking-id-link {
  cursor: pointer;
  color: var(--cx-color-secondary);
  text-decoration: underline;
}
cx-my-account-v2-consignment-tracking .cx-list-status .cx-item-list-tracking-id .cx-tracking-id-link:hover {
  text-decoration: none;
}
cx-my-account-v2-consignment-tracking .cx-list-status .cx-consignment-status-date {
  font-weight: var(--cx-font-weight-semi);
}

cx-my-account-v2-download-invoices .cx-modal-container .cx-modal-content .cx-dialog-title {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}

cx-replenishment-order-cancellation .cx-cancel-replenishment-btns,
cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-btns {
  display: flex;
}
@media (max-width: 575.98px) {
  cx-replenishment-order-cancellation .cx-cancel-replenishment-btns .btn-secondary,
  cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-btns .btn-secondary {
    margin: 0 0 1rem;
  }
}

cx-replenishment-order-cancellation .cx-cancel-replenishment-btns {
  justify-content: flex-end;
  margin: 2rem 0;
}

cx-replenishment-order-cancellation-dialog {
  color: var(--cx-color-text);
  background-color: rgba(0, 0, 0, 0.5);
}
cx-replenishment-order-cancellation-dialog .cx-cancel-replenishment-dialog-foreground .cx-cancel-replenishment-dialog-content .cx-cancel-replenishment-dialog-description {
  padding-top: 10px;
  padding-inline-start: 25px;
  padding-inline-end: 25px;
  padding-bottom: 0;
}
cx-my-account-v2-orders {
  margin: 1rem 0rem;
  border: 1px solid var(--cx-color-medium);
}
cx-my-account-v2-orders .cx-my-account-view-header {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
}
cx-my-account-v2-orders .cx-my-account-view-header .cx-my-account-view-heading {
  font-size: 16px;
  font-weight: 700;
}
cx-my-account-v2-orders .cx-my-account-view-header .cx-my-account-view-show-more {
  font-size: 14px;
  font-weight: 600;
}
cx-my-account-v2-orders .cx-my-account-view-body .cx-my-account-view-order {
  border-collapse: collapse;
  margin: 0;
}
cx-my-account-v2-orders .cx-my-account-view-body .cx-my-account-view-order .cx-my-account-view-order-header {
  display: flex;
  justify-content: space-between;
  margin: 1rem 1rem 0 1rem;
  padding: 15px 30px;
  background-color: var(--cx-color-light);
  border: 1px solid var(--cx-color-medium);
  border-radius: 0;
}
cx-my-account-v2-orders .cx-my-account-view-body .cx-my-account-view-order .cx-my-account-view-order-header .cx-my-account-view-status {
  font-size: 16px;
  font-weight: 700;
}
cx-my-account-v2-orders .cx-my-account-view-body .cx-my-account-view-order .cx-my-account-view-order-header .cx-my-account-view-code {
  font-size: 12px;
  font-weight: 400;
  color: var(--cx-color-secondary);
}
cx-my-account-v2-orders .cx-my-account-view-body .cx-my-account-view-order .cx-my-account-view-order-body {
  display: flex;
  justify-content: space-between;
  margin: 0 1rem 1.5rem 1rem;
  padding: 15px;
  border: 1px solid var(--cx-color-medium);
}
cx-my-account-v2-orders .cx-my-account-view-body .cx-my-account-view-order .cx-my-account-view-order-body:first-child {
  border-radius: 0;
}
cx-my-account-v2-orders .cx-my-account-view-body .cx-my-account-view-order .cx-my-account-view-order-body .cx-my-account-view-order-column-1 {
  width: 70%;
}
cx-my-account-v2-orders .cx-my-account-view-body .cx-my-account-view-order .cx-my-account-view-order-body .cx-my-account-view-order-column-1 .cx-my-account-view-order-column-1-image {
  float: left;
  margin: 1 rem;
  padding: 15px;
}
cx-my-account-v2-orders .cx-my-account-view-body .cx-my-account-view-order .cx-my-account-view-order-body .cx-my-account-view-order-column-1 .cx-my-account-view-order-column-1-image .cx-my-account-view-order-img {
  width: 124px;
  height: 124px;
  display: inline-end;
}
cx-my-account-v2-orders .cx-my-account-view-body .cx-my-account-view-order .cx-my-account-view-order-body .cx-my-account-view-order-column-1 .cx-my-account-view-order-column-1-details .cx-my-account-view-order-column-1-details-top {
  margin: 1 rem;
  padding: 15px 15px 15px 20px;
}
cx-my-account-v2-orders .cx-my-account-view-body .cx-my-account-view-order .cx-my-account-view-order-body .cx-my-account-view-order-column-1 .cx-my-account-view-order-column-1-details .cx-my-account-view-order-column-1-details-top .cx-my-account-view-product-name {
  font-size: 14px;
  font-weight: 600;
}
cx-my-account-v2-orders .cx-my-account-view-body .cx-my-account-view-order .cx-my-account-view-order-body .cx-my-account-view-order-column-1 .cx-my-account-view-order-column-1-details .cx-my-account-view-order-column-1-details-top .cx-my-account-view-purchased-on {
  font-size: 12px;
  font-weight: 400;
}
cx-my-account-v2-orders .cx-my-account-view-body .cx-my-account-view-order .cx-my-account-view-order-body .cx-my-account-view-order-column-1 .cx-my-account-view-order-column-1-details .cx-my-account-view-order-column-1-details-top .cx-my-account-view-item-count {
  font-size: 12px;
  font-weight: 400;
}
cx-my-account-v2-orders .cx-my-account-view-body .cx-my-account-view-order .cx-my-account-view-order-body .cx-my-account-view-order-column-1 .cx-my-account-view-order-column-1-details .cx-my-account-view-order-column-1-details-bottom {
  padding: 15px 15px 15px 20px;
}
cx-my-account-v2-orders .cx-my-account-view-body .cx-my-account-view-order .cx-my-account-view-order-body .cx-my-account-view-order-column-1 .cx-my-account-view-order-column-1-details .cx-my-account-view-order-column-1-details-bottom .cx-my-account-view-total-price {
  font-size: 14px;
  font-weight: 600;
}
cx-my-account-v2-orders .cx-my-account-view-body .cx-my-account-view-order .cx-my-account-view-order-body .cx-my-account-view-order-column-2 {
  padding: 15px;
  width: 30%;
  display: flex;
  justify-content: right;
  color: var(--cx-color-secondary);
  font-size: 14px;
  font-weight: 600;
  align-items: flex-end;
}
cx-my-account-v2-orders .cx-my-account-no-order {
  margin: 1rem;
  padding: 15px;
}