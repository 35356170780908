.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog, cx-import-entries-dialog .cx-import-entries-dialog, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog, cx-clear-cart-dialog .cx-clear-cart-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog, .modal.fade cx-import-entries-dialog .cx-import-entries-dialog, cx-import-entries-dialog .modal.fade .cx-import-entries-dialog, .modal.fade cx-saved-cart-form-dialog .cx-saved-cart-form-dialog, cx-saved-cart-form-dialog .modal.fade .cx-saved-cart-form-dialog, .modal.fade cx-clear-cart-dialog .cx-clear-cart-dialog, cx-clear-cart-dialog .modal.fade .cx-clear-cart-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog, .modal.fade cx-import-entries-dialog .cx-import-entries-dialog, cx-import-entries-dialog .modal.fade .cx-import-entries-dialog, .modal.fade cx-saved-cart-form-dialog .cx-saved-cart-form-dialog, cx-saved-cart-form-dialog .modal.fade .cx-saved-cart-form-dialog, .modal.fade cx-clear-cart-dialog .cx-clear-cart-dialog, cx-clear-cart-dialog .modal.fade .cx-clear-cart-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog, .modal.show cx-import-entries-dialog .cx-import-entries-dialog, cx-import-entries-dialog .modal.show .cx-import-entries-dialog, .modal.show cx-saved-cart-form-dialog .cx-saved-cart-form-dialog, cx-saved-cart-form-dialog .modal.show .cx-saved-cart-form-dialog, .modal.show cx-clear-cart-dialog .cx-clear-cart-dialog, cx-clear-cart-dialog .modal.show .cx-clear-cart-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog, .modal.modal-static cx-import-entries-dialog .cx-import-entries-dialog, cx-import-entries-dialog .modal.modal-static .cx-import-entries-dialog, .modal.modal-static cx-saved-cart-form-dialog .cx-saved-cart-form-dialog, cx-saved-cart-form-dialog .modal.modal-static .cx-saved-cart-form-dialog, .modal.modal-static cx-clear-cart-dialog .cx-clear-cart-dialog, cx-clear-cart-dialog .modal.modal-static .cx-clear-cart-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content, .modal-dialog-scrollable cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container, cx-import-entries-dialog .cx-import-entries-dialog .modal-dialog-scrollable .cx-import-entries-container, .modal-dialog-scrollable cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .modal-dialog-scrollable .cx-saved-cart-form-container, .modal-dialog-scrollable cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container, cx-clear-cart-dialog .cx-clear-cart-dialog .modal-dialog-scrollable .cx-clear-cart-container {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body, .modal-dialog-scrollable cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-summary, cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .modal-dialog-scrollable cx-import-entries-summary, .modal-dialog-scrollable cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-form, cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .modal-dialog-scrollable cx-import-entries-form,
.modal-dialog-scrollable cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-to-new-saved-cart-form,
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .modal-dialog-scrollable cx-import-to-new-saved-cart-form, .modal-dialog-scrollable cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-body, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .modal-dialog-scrollable .cx-saved-cart-form-body, .modal-dialog-scrollable cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .cx-clear-cart-body, cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .modal-dialog-scrollable .cx-clear-cart-body {
  overflow-y: auto;
}

.modal-dialog-centered, cx-import-entries-dialog .cx-import-entries-dialog, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog, cx-clear-cart-dialog .cx-clear-cart-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content, cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container, cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body, cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-summary, cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-form,
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-to-new-saved-cart-form, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-body, cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .cx-clear-cart-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog, cx-import-entries-dialog .cx-import-entries-dialog, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog, cx-clear-cart-dialog .cx-clear-cart-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered, cx-import-entries-dialog .cx-import-entries-dialog, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog, cx-clear-cart-dialog .cx-clear-cart-dialog {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content, .modal-fullscreen cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container, cx-import-entries-dialog .cx-import-entries-dialog .modal-fullscreen .cx-import-entries-container, .modal-fullscreen cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .modal-fullscreen .cx-saved-cart-form-container, .modal-fullscreen cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container, cx-clear-cart-dialog .cx-clear-cart-dialog .modal-fullscreen .cx-clear-cart-container {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body, .modal-fullscreen cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-summary, cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .modal-fullscreen cx-import-entries-summary, .modal-fullscreen cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-form, cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .modal-fullscreen cx-import-entries-form,
.modal-fullscreen cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-to-new-saved-cart-form,
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .modal-fullscreen cx-import-to-new-saved-cart-form, .modal-fullscreen cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-body, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .modal-fullscreen .cx-saved-cart-form-body, .modal-fullscreen cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .cx-clear-cart-body, cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .modal-fullscreen .cx-clear-cart-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content, .modal-fullscreen-sm-down cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container, cx-import-entries-dialog .cx-import-entries-dialog .modal-fullscreen-sm-down .cx-import-entries-container, .modal-fullscreen-sm-down cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .modal-fullscreen-sm-down .cx-saved-cart-form-container, .modal-fullscreen-sm-down cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container, cx-clear-cart-dialog .cx-clear-cart-dialog .modal-fullscreen-sm-down .cx-clear-cart-container {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body, .modal-fullscreen-sm-down cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-summary, cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .modal-fullscreen-sm-down cx-import-entries-summary, .modal-fullscreen-sm-down cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-form, cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .modal-fullscreen-sm-down cx-import-entries-form,
  .modal-fullscreen-sm-down cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-to-new-saved-cart-form,
  cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .modal-fullscreen-sm-down cx-import-to-new-saved-cart-form, .modal-fullscreen-sm-down cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-body, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .modal-fullscreen-sm-down .cx-saved-cart-form-body, .modal-fullscreen-sm-down cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .cx-clear-cart-body, cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .modal-fullscreen-sm-down .cx-clear-cart-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content, .modal-fullscreen-md-down cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container, cx-import-entries-dialog .cx-import-entries-dialog .modal-fullscreen-md-down .cx-import-entries-container, .modal-fullscreen-md-down cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .modal-fullscreen-md-down .cx-saved-cart-form-container, .modal-fullscreen-md-down cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container, cx-clear-cart-dialog .cx-clear-cart-dialog .modal-fullscreen-md-down .cx-clear-cart-container {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body, .modal-fullscreen-md-down cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-summary, cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .modal-fullscreen-md-down cx-import-entries-summary, .modal-fullscreen-md-down cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-form, cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .modal-fullscreen-md-down cx-import-entries-form,
  .modal-fullscreen-md-down cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-to-new-saved-cart-form,
  cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .modal-fullscreen-md-down cx-import-to-new-saved-cart-form, .modal-fullscreen-md-down cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-body, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .modal-fullscreen-md-down .cx-saved-cart-form-body, .modal-fullscreen-md-down cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .cx-clear-cart-body, cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .modal-fullscreen-md-down .cx-clear-cart-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content, .modal-fullscreen-lg-down cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container, cx-import-entries-dialog .cx-import-entries-dialog .modal-fullscreen-lg-down .cx-import-entries-container, .modal-fullscreen-lg-down cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .modal-fullscreen-lg-down .cx-saved-cart-form-container, .modal-fullscreen-lg-down cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container, cx-clear-cart-dialog .cx-clear-cart-dialog .modal-fullscreen-lg-down .cx-clear-cart-container {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body, .modal-fullscreen-lg-down cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-summary, cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .modal-fullscreen-lg-down cx-import-entries-summary, .modal-fullscreen-lg-down cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-form, cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .modal-fullscreen-lg-down cx-import-entries-form,
  .modal-fullscreen-lg-down cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-to-new-saved-cart-form,
  cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .modal-fullscreen-lg-down cx-import-to-new-saved-cart-form, .modal-fullscreen-lg-down cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-body, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .modal-fullscreen-lg-down .cx-saved-cart-form-body, .modal-fullscreen-lg-down cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .cx-clear-cart-body, cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .modal-fullscreen-lg-down .cx-clear-cart-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content, .modal-fullscreen-xl-down cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container, cx-import-entries-dialog .cx-import-entries-dialog .modal-fullscreen-xl-down .cx-import-entries-container, .modal-fullscreen-xl-down cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .modal-fullscreen-xl-down .cx-saved-cart-form-container, .modal-fullscreen-xl-down cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container, cx-clear-cart-dialog .cx-clear-cart-dialog .modal-fullscreen-xl-down .cx-clear-cart-container {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body, .modal-fullscreen-xl-down cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-summary, cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .modal-fullscreen-xl-down cx-import-entries-summary, .modal-fullscreen-xl-down cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-form, cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .modal-fullscreen-xl-down cx-import-entries-form,
  .modal-fullscreen-xl-down cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-to-new-saved-cart-form,
  cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .modal-fullscreen-xl-down cx-import-to-new-saved-cart-form, .modal-fullscreen-xl-down cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-body, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .modal-fullscreen-xl-down .cx-saved-cart-form-body, .modal-fullscreen-xl-down cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .cx-clear-cart-body, cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .modal-fullscreen-xl-down .cx-clear-cart-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content, .modal-fullscreen-xxl-down cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container, cx-import-entries-dialog .cx-import-entries-dialog .modal-fullscreen-xxl-down .cx-import-entries-container, .modal-fullscreen-xxl-down cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .modal-fullscreen-xxl-down .cx-saved-cart-form-container, .modal-fullscreen-xxl-down cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container, cx-clear-cart-dialog .cx-clear-cart-dialog .modal-fullscreen-xxl-down .cx-clear-cart-container {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body, .modal-fullscreen-xxl-down cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-summary, cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .modal-fullscreen-xxl-down cx-import-entries-summary, .modal-fullscreen-xxl-down cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-form, cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .modal-fullscreen-xxl-down cx-import-entries-form,
  .modal-fullscreen-xxl-down cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-to-new-saved-cart-form,
  cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .modal-fullscreen-xxl-down cx-import-to-new-saved-cart-form, .modal-fullscreen-xxl-down cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-body, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .modal-fullscreen-xxl-down .cx-saved-cart-form-body, .modal-fullscreen-xxl-down cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .cx-clear-cart-body, cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .modal-fullscreen-xxl-down .cx-clear-cart-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(31, 123, 192, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-header {
  padding: 1rem 1rem;
}
.modal-body, cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-summary, cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-form,
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-to-new-saved-cart-form, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-body, cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .cx-clear-cart-body {
  padding: 16px 30px 30px;
}
.modal-content, cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container, cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container, cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container {
  border-radius: 0;
  border: none;
}
.modal .close {
  font-size: 38px;
  font-weight: 100;
  bottom: 5px;
  position: relative;
  margin-inline-start: 0;
  margin-inline-end: 0;
  align-self: flex-end;
}
@media (max-width: 767.98px) {
  .modal .close {
    margin-bottom: 0;
  }
}
.modal[aria-hidden=false] {
  display: block;
}

.cx-modal-container {
  display: flex;
  align-items: center;
  margin: auto;
  height: 100%;
  overflow-y: auto;
  max-width: 768px !important;
  min-width: 768px !important;
}
@media (max-width: 575.98px) {
  .cx-modal-container {
    margin: 0 !important;
    min-width: 100% !important;
    max-width: 100% !important;
    overflow-y: initial;
    height: 100%;
    overflow-y: auto;
  }
}
@media (min-width: 768px) {
  .cx-modal-container {
    max-width: 768px !important;
    min-width: 768px !important;
  }
}

.cx-modal-header {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  padding-inline-end: 1.875rem;
  padding-bottom: 0;
  padding-inline-start: 1.875rem;
}

.cx-modal-content {
  background-color: var(--cx-color-inverse);
  width: 100%;
}

.cx-modal-footer {
  padding: 0px 27px 30px;
}

@media (min-width: 992px) {
  .cx-asm-dialog {
    max-width: 95% !important;
    min-width: 95% !important;
  }
}
.cx-asm-dialog .cx-modal-content {
  border-radius: 16px;
}

.cx-agnostic-table table, cx-wish-list table, cx-quick-order-table table, cx-cart-item-list table {
  margin: 1rem 0;
  border-collapse: collapse;
  width: 100%;
}
.cx-agnostic-table th, cx-wish-list th, cx-quick-order-table th, cx-cart-item-list th,
.cx-agnostic-table td,
cx-wish-list td,
cx-quick-order-table td,
cx-cart-item-list td {
  padding: 1.25rem 0.1rem 1.25rem 0.5rem;
  vertical-align: middle;
  text-align: center;
}
.cx-agnostic-table th:nth-of-type(1), cx-wish-list th:nth-of-type(1), cx-quick-order-table th:nth-of-type(1), cx-cart-item-list th:nth-of-type(1),
.cx-agnostic-table td:nth-of-type(1),
cx-wish-list td:nth-of-type(1),
cx-quick-order-table td:nth-of-type(1),
cx-cart-item-list td:nth-of-type(1) {
  text-align: start;
}
.cx-agnostic-table th:nth-last-of-type(1), cx-wish-list th:nth-last-of-type(1), cx-quick-order-table th:nth-last-of-type(1), cx-cart-item-list th:nth-last-of-type(1),
.cx-agnostic-table td:nth-last-of-type(1),
cx-wish-list td:nth-last-of-type(1),
cx-quick-order-table td:nth-last-of-type(1),
cx-cart-item-list td:nth-last-of-type(1) {
  text-align: end;
}
.cx-agnostic-table tr, cx-wish-list tr, cx-quick-order-table tr, cx-cart-item-list tr {
  border-top: 1px solid var(--cx-color-medium);
  padding: 1.25rem 0;
}
.cx-agnostic-table th, cx-wish-list th, cx-quick-order-table th, cx-cart-item-list th {
  vertical-align: bottom;
  text-transform: capitalize;
  text-align: center;
  color: var(--cx-color-secondary);
  font-weight: var(--cx-font-weight-bold);
  font-size: var(--cx-font-size, 0.875rem);
  white-space: nowrap;
}
.cx-agnostic-table th:nth-last-of-type(1), cx-wish-list th:nth-last-of-type(1), cx-quick-order-table th:nth-last-of-type(1), cx-cart-item-list th:nth-last-of-type(1) {
  padding-inline-end: 16px;
}
.cx-agnostic-table .cx-mobile-header, cx-wish-list .cx-mobile-header, cx-quick-order-table .cx-mobile-header, cx-cart-item-list .cx-mobile-header {
  display: none;
}
.cx-agnostic-table .cx-mobile-only, cx-wish-list .cx-mobile-only, cx-quick-order-table .cx-mobile-only, cx-cart-item-list .cx-mobile-only {
  display: none;
}
@media (max-width: 767.98px) {
  .cx-agnostic-table table, cx-wish-list table, cx-quick-order-table table, cx-cart-item-list table,
  .cx-agnostic-table tr,
  cx-wish-list tr,
  cx-quick-order-table tr,
  cx-cart-item-list tr,
  .cx-agnostic-table td,
  cx-wish-list td,
  cx-quick-order-table td,
  cx-cart-item-list td {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
  }
  .cx-agnostic-table td, cx-wish-list td, cx-quick-order-table td, cx-cart-item-list td {
    align-items: center;
    padding: 0.313rem;
  }
  .cx-agnostic-table thead, cx-wish-list thead, cx-quick-order-table thead, cx-cart-item-list thead {
    display: none;
  }
  .cx-agnostic-table .cx-mobile-header, cx-wish-list .cx-mobile-header, cx-quick-order-table .cx-mobile-header, cx-cart-item-list .cx-mobile-header {
    display: block;
    white-space: nowrap;
    text-align: start;
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.2222222222);
    overflow-wrap: break-word;
    padding-inline-end: 1rem;
    min-width: 5rem;
  }
  .cx-agnostic-table .cx-mobile-only, cx-wish-list .cx-mobile-only, cx-quick-order-table .cx-mobile-only, cx-cart-item-list .cx-mobile-only {
    display: flex;
  }
}

cx-mini-cart {
  margin-inline-start: 0.75rem;
  display: block;
}
@media (min-width: 768px) {
  cx-mini-cart {
    margin-inline-end: 0.85rem;
  }
}
cx-mini-cart a {
  display: flex;
  background: var(--cx-color-primary);
  min-width: 90px;
  height: 47px;
  padding: 10px;
  color: var(--cx-color-inverse);
  text-decoration: none;
  justify-content: space-around;
}
cx-mini-cart a:hover {
  color: currentColor;
  text-decoration: none;
}
cx-mini-cart a .count {
  color: currentColor;
  align-self: center;
  padding-inline-start: 7px;
  font-size: var(--cx-font-size, 1.188rem);
  font-weight: var(--cx-font-weight-bold);
}
cx-mini-cart a .total {
  display: none;
}
@media (max-width: 575.98px) {
  cx-mini-cart a {
    width: 55px;
    height: 57px;
    background-position: center top 4px;
    flex-direction: column;
    justify-content: center;
  }
  cx-mini-cart a .count {
    padding-inline-end: 0;
    margin-bottom: -5px;
  }
}

cx-added-to-cart-dialog {
  background-color: rgba(0, 0, 0, 0.5);
}
@media (max-width: 767.98px) {
  cx-added-to-cart-dialog .cx-modal-content {
    height: 100%;
  }
}
cx-added-to-cart-dialog .cx-dialog-header {
  padding-top: 2rem;
  padding-inline-end: 1.75rem;
  padding-bottom: 0.85rem;
  padding-inline-start: 1.75rem;
  border-width: 0;
  display: flex;
}
cx-added-to-cart-dialog .cx-dialog-title {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  align-self: flex-start;
}
cx-added-to-cart-dialog .cx-dialog-body {
  padding: 1rem;
}
@media (max-width: 575.98px) {
  cx-added-to-cart-dialog .cx-dialog-body {
    padding: 0;
    background-color: var(--cx-color-inverse);
  }
}
cx-added-to-cart-dialog .cx-dialog-body .cx-remove-btn {
  max-width: 100%;
  padding: 0;
  flex: 0 0 100%;
}
cx-added-to-cart-dialog .cx-dialog-row {
  margin: 0;
  display: flex;
  padding-top: 0;
  padding-inline-end: 0.875rem;
  padding-bottom: 0.85rem;
  padding-inline-start: 2.875rem;
  max-width: 100%;
  flex-wrap: wrap;
}
@media (max-width: 575.98px) {
  cx-added-to-cart-dialog .cx-dialog-row {
    flex-direction: column;
    padding: 0;
  }
}
@media (max-width: 575.98px) {
  cx-added-to-cart-dialog .cx-dialog-item {
    padding: 2rem;
  }
}
cx-added-to-cart-dialog .cx-dialog-item .cx-image-container a {
  padding: 0;
  max-width: 65px;
}
@media (min-width: 768px) {
  cx-added-to-cart-dialog .cx-dialog-item .cx-compact .cx-info {
    padding: 0 25px;
  }
}
cx-added-to-cart-dialog .cx-dialog-item .cx-compact .cx-price .cx-label,
cx-added-to-cart-dialog .cx-dialog-item .cx-compact .cx-quantity .cx-label,
cx-added-to-cart-dialog .cx-dialog-item .cx-compact .cx-total .cx-label {
  font-weight: var(--cx-font-weight-normal);
}
cx-added-to-cart-dialog .cx-dialog-item .cx-compact .cx-actions {
  justify-content: flex-start;
}
cx-added-to-cart-dialog .cx-dialog-separator {
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: var(--cx-color-light);
}
cx-added-to-cart-dialog .cx-dialog-actions {
  display: flex;
  flex-direction: column;
  padding-inline-start: 2.5rem;
  padding-inline-end: 1rem;
  border-width: 0 0 0 0;
  border-inline-start-width: 1px;
  border-style: solid;
  border-color: var(--cx-color-light);
}
@media (max-width: 575.98px) {
  cx-added-to-cart-dialog .cx-dialog-actions {
    border-width: 0;
    padding: 1.875rem;
  }
}
cx-added-to-cart-dialog .cx-dialog-promotions {
  padding: 0 0 1.25rem 0;
}
cx-added-to-cart-dialog .cx-dialog-total {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  display: flex;
  justify-content: space-between;
  padding: 0 0 1.25rem 0;
}
cx-added-to-cart-dialog .cx-dialog-buttons {
  display: flex;
  flex-direction: column;
}
cx-added-to-cart-dialog .cx-dialog-buttons .btn-primary {
  margin: 0 0 0.625rem 0;
}
cx-added-to-cart-dialog .cx-dialog-pickup-store {
  padding-inline-start: 4.063rem;
}
cx-added-to-cart-dialog .cx-dialog-pickup-store .cx-dialog-pickup-store-name {
  font-weight: var(--cx-font-weight-semi);
}

cx-saved-cart-details-items, cx-cart-details {
  display: block;
}
cx-saved-cart-details-items .cart-details-wrapper, cx-cart-details .cart-details-wrapper {
  padding: 2rem 0;
}
cx-saved-cart-details-items .cx-promotions, cx-cart-details .cx-promotions {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  color: var(--cx-color-success);
  padding: 0.5rem 0;
}
cx-saved-cart-details-items .cx-promotions li, cx-cart-details .cx-promotions li {
  line-height: 1.6;
}

@media (max-width: 575.98px) {
  cx-cart-item .cx-info-container {
    display: flex;
    flex-direction: column;
  }
}
cx-cart-item .cx-name {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  overflow-wrap: break-word;
  padding: 0;
}
cx-cart-item .cx-name h3 {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
}
@media (min-width: 1200px) {
  cx-cart-item .cx-name {
    min-width: 14rem;
  }
}
cx-cart-item .cx-name .cx-link {
  color: var(--cx-color-text);
  text-decoration: none;
  display: block;
}
cx-cart-item .cx-name .cx-link:hover {
  color: var(--cx-color-primary);
}
cx-cart-item .cx-code {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  color: var(--cx-color-secondary);
  padding: 0.625rem 0;
}
cx-cart-item .cx-property {
  display: flex;
}
cx-cart-item .cx-property:last-of-type {
  margin-bottom: 0.5rem;
}
cx-cart-item .cx-label {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  overflow-wrap: break-word;
  padding-inline-end: 1rem;
}
@media (max-width: 575.98px) {
  cx-cart-item .cx-label {
    min-width: 5rem;
  }
}
cx-cart-item .cx-value {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  overflow-wrap: break-word;
  font-weight: 400;
}
@media (max-width: 575.98px) {
  cx-cart-item .cx-value {
    font-size: var(--cx-font-size, 0.875rem);
    font-weight: var(--cx-font-weight-normal);
    line-height: var(--cx-line-height, 1.2222222222);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  cx-cart-item .cx-value {
    padding-inline-start: 1em;
  }
}
cx-cart-item .cx-price {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: "normal";
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  cx-cart-item .cx-price {
    padding-inline-start: 0px;
  }
}
cx-cart-item .cx-price .cx-old {
  text-decoration: line-through;
  color: var(--cx-color-secondary);
  padding-inline-end: 1rem;
}
cx-cart-item .cx-price .cx-new {
  color: var(--cx-color-primary);
}
cx-cart-item .cx-image-container {
  padding: 0;
}
cx-cart-item .cx-image-container a {
  display: block;
  padding: 0 1vw;
  height: 100%;
}
cx-cart-item .cx-quantity {
  display: flex;
  justify-content: center;
  align-items: center;
}
cx-cart-item .cx-total {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0;
  padding-inline-start: 0;
}
@media (max-width: 575.98px) {
  cx-cart-item .cx-total {
    padding-inline-start: 15px;
  }
}
cx-cart-item .cx-total .cx-value {
  text-align: end;
  word-break: break-word;
}
cx-cart-item .cx-promo {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  color: var(--cx-color-success);
  padding: 0.75rem 0;
  margin: 0;
}
cx-cart-item .cx-availability {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  color: var(--cx-color-danger);
  padding: 0.75rem 0;
  margin: 0;
}
cx-cart-item .cx-actions {
  display: flex;
  justify-content: flex-end;
  padding: 0;
}
@media (max-width: 575.98px) {
  cx-cart-item .cx-actions {
    display: flex;
    justify-content: flex-start;
    padding: 0;
  }
}
cx-cart-item .cx-actions button.link {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  color: var(--cx-color-text);
  border-width: 0;
  background-color: transparent;
}
cx-cart-item .cx-actions button.link:hover {
  color: var(--cx-color-primary);
}
@media (max-width: 575.98px) {
  cx-cart-item .cx-price,
  cx-cart-item .cx-quantity,
  cx-cart-item .cx-total {
    justify-content: flex-start;
  }
  cx-cart-item .cx-price .cx-label,
  cx-cart-item .cx-quantity .cx-label,
  cx-cart-item .cx-total .cx-label {
    width: 100px;
  }
}
@media (max-width: 575.98px) {
  cx-cart-item .cx-price,
  cx-cart-item .cx-quantity {
    padding-bottom: 10px;
  }
}
@media (max-width: 575.98px) {
  cx-cart-item .cx-quantity .cx-value {
    margin: 0 0 0 20px;
  }
  cx-cart-item .cx-quantity .readonly-value {
    background-color: rgb(230, 230, 230);
  }
}
@media (max-width: 575.98px) {
  cx-cart-item .cx-actions .link {
    display: block;
    text-align: start;
  }
  cx-cart-item .cx-actions .cx-sfl-btn {
    text-align: start;
    padding-inline-start: 0;
  }
}
cx-cart-item .cx-compact {
  display: flex;
  flex-direction: row;
}
cx-cart-item .cx-compact .cx-image-container {
  padding: 0;
}
cx-cart-item .cx-compact .cx-info-container {
  display: flex;
  flex-direction: column;
  margin: 0;
}
cx-cart-item .cx-compact .cx-price,
cx-cart-item .cx-compact .cx-quantity,
cx-cart-item .cx-compact .cx-total,
cx-cart-item .cx-compact .cx-actions {
  justify-content: flex-start;
  padding: 0 0 10px 0;
}
cx-cart-item .cx-compact .cx-price .cx-label,
cx-cart-item .cx-compact .cx-quantity .cx-label,
cx-cart-item .cx-compact .cx-total .cx-label,
cx-cart-item .cx-compact .cx-actions .cx-label {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  min-width: 5rem;
}
cx-cart-item .cx-compact .cx-price .cx-value,
cx-cart-item .cx-compact .cx-quantity .cx-value,
cx-cart-item .cx-compact .cx-total .cx-value,
cx-cart-item .cx-compact .cx-actions .cx-value {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-cart-item .cx-compact .cx-price button.link,
cx-cart-item .cx-compact .cx-quantity button.link,
cx-cart-item .cx-compact .cx-total button.link,
cx-cart-item .cx-compact .cx-actions button.link {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-cart-item .cx-compact .cx-quantity .cx-value {
  margin: 0;
}

cx-wish-list .cx-item-list-header, cx-quick-order-table .cx-item-list-header, cx-cart-item-list .cx-item-list-header {
  border-bottom: none;
}
cx-wish-list .cx-item-list-header .cx-item-list-qty, cx-quick-order-table .cx-item-list-header .cx-item-list-qty, cx-cart-item-list .cx-item-list-header .cx-item-list-qty {
  text-transform: uppercase;
}
cx-wish-list .cx-item-list-items tr:last-child, cx-quick-order-table .cx-item-list-items tr:last-child, cx-cart-item-list .cx-item-list-items tr:last-child {
  border-bottom: 1px solid var(--cx-color-medium);
}
cx-wish-list td.cx-actions cx-add-to-cart, cx-quick-order-table td.cx-actions cx-add-to-cart, cx-cart-item-list td.cx-actions cx-add-to-cart,
cx-wish-list td.cx-actions button,
cx-quick-order-table td.cx-actions button,
cx-cart-item-list td.cx-actions button {
  white-space: nowrap;
  display: block;
  margin-inline-start: auto;
}
@media (max-width: 767.98px) {
  cx-wish-list td.cx-actions cx-add-to-cart, cx-quick-order-table td.cx-actions cx-add-to-cart, cx-cart-item-list td.cx-actions cx-add-to-cart,
  cx-wish-list td.cx-actions button,
  cx-quick-order-table td.cx-actions button,
  cx-cart-item-list td.cx-actions button {
    margin-inline-start: auto;
  }
}
@media (max-width: 575.98px) {
  cx-wish-list td.cx-actions cx-add-to-cart, cx-quick-order-table td.cx-actions cx-add-to-cart, cx-cart-item-list td.cx-actions cx-add-to-cart,
  cx-wish-list td.cx-actions button,
  cx-quick-order-table td.cx-actions button,
  cx-cart-item-list td.cx-actions button {
    min-height: 48px;
  }
}
cx-wish-list td.cx-actions .cx-remove-btn, cx-quick-order-table td.cx-actions .cx-remove-btn, cx-cart-item-list td.cx-actions .cx-remove-btn {
  margin-inline-start: auto;
}
@media (max-width: 767.98px) {
  cx-wish-list td.cx-actions .cx-remove-btn, cx-quick-order-table td.cx-actions .cx-remove-btn, cx-cart-item-list td.cx-actions .cx-remove-btn {
    margin: 0;
  }
}
cx-wish-list .cx-table-item-container, cx-quick-order-table .cx-table-item-container, cx-cart-item-list .cx-table-item-container {
  display: inline-grid;
  grid-template-columns: 5rem 14rem;
  gap: 1rem;
  text-indent: 0;
}
cx-wish-list .cx-name, cx-quick-order-table .cx-name, cx-cart-item-list .cx-name {
  margin-top: 0.5rem;
  font-size: var(--cx-font-size, 1rem);
}
cx-wish-list .cx-code, cx-quick-order-table .cx-code, cx-cart-item-list .cx-code {
  color: var(--cx-color-secondary);
  margin-top: 0.5em;
}
cx-wish-list .cx-link, cx-quick-order-table .cx-link, cx-cart-item-list .cx-link {
  text-decoration: none;
  color: var(--cx-color-text);
  font-weight: var(--cx-font-weight-bold);
}
cx-wish-list .cx-total, cx-quick-order-table .cx-total, cx-cart-item-list .cx-total {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
}
cx-wish-list .cx-cart-addons, cx-quick-order-table .cx-cart-addons, cx-cart-item-list .cx-cart-addons {
  display: flex;
  width: 100%;
  flex-direction: column;
}
cx-wish-list .cx-cart-addons cx-configurator-cart-entry-info, cx-quick-order-table .cx-cart-addons cx-configurator-cart-entry-info, cx-cart-item-list .cx-cart-addons cx-configurator-cart-entry-info {
  margin-inline-start: 6rem;
}
cx-wish-list .cx-cart-addons cx-cart-pickup-options-container, cx-quick-order-table .cx-cart-addons cx-cart-pickup-options-container, cx-cart-item-list .cx-cart-addons cx-cart-pickup-options-container {
  margin-inline-start: 6rem;
}
@media (max-width: 767.98px) {
  cx-wish-list .cx-table-item-container, cx-quick-order-table .cx-table-item-container, cx-cart-item-list .cx-table-item-container {
    grid-template-columns: 5em auto;
  }
  cx-wish-list .cx-table-item-container .cx-price, cx-quick-order-table .cx-table-item-container .cx-price, cx-cart-item-list .cx-table-item-container .cx-price {
    display: none;
  }
  cx-wish-list .cx-value cx-item-counter button[type=button], cx-quick-order-table .cx-value cx-item-counter button[type=button], cx-cart-item-list .cx-value cx-item-counter button[type=button] {
    margin-bottom: 0em;
  }
  cx-wish-list .cx-actions button, cx-quick-order-table .cx-actions button, cx-cart-item-list .cx-actions button {
    margin-inline-start: 0;
    text-align: start;
  }
  cx-wish-list td:nth-of-type(1), cx-quick-order-table td:nth-of-type(1), cx-cart-item-list td:nth-of-type(1) {
    padding-inline-start: 0;
  }
  cx-wish-list td:not(:nth-of-type(1)), cx-quick-order-table td:not(:nth-of-type(1)), cx-cart-item-list td:not(:nth-of-type(1)) {
    font-size: var(--cx-font-size, 0.875rem);
    margin-inline-start: 5.7rem;
  }
}
@media (max-width: 767.98px) and (max-width: 575.98px) {
  cx-wish-list .cx-quantity .cx-mobile-header, cx-quick-order-table .cx-quantity .cx-mobile-header, cx-cart-item-list .cx-quantity .cx-mobile-header {
    margin-bottom: 5px;
  }
}

cx-cart-totals {
  padding-top: 2rem;
  padding-inline-end: 0;
  padding-inline-start: 3rem;
}
@media (max-width: 767.98px) {
  cx-cart-totals {
    padding-top: 2rem;
    padding-inline-end: 3rem;
    padding-inline-start: 0rem;
  }
}
@media (max-width: 575.98px) {
  cx-cart-totals {
    padding-inline-end: 0;
  }
}
cx-cart-totals cx-order-summary {
  padding: 0 0 1rem;
}
@media (max-width: 767.98px) {
  cx-cart-totals cx-order-summary {
    padding: 0 0 2rem;
  }
}

cx-order-summary {
  display: block;
  padding-top: 16px;
  padding-inline-end: 15px;
  padding-bottom: 0;
  padding-inline-start: 15px;
  margin: 0;
}
cx-order-summary .cx-summary-heading {
  text-transform: capitalize;
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: var(--cx-font-weight-semi);
  border-bottom: 1px solid var(--cx-color-medium);
  padding-bottom: 1.625rem;
}
@media (max-width: 767.98px) {
  cx-order-summary .cx-summary-heading {
    margin-bottom: 0.438rem;
    padding-bottom: 0.938rem;
  }
}
cx-order-summary .cx-summary-label {
  text-align: start;
  padding: 0;
}
cx-order-summary .cx-summary-amount {
  text-align: end;
  padding: 0;
}
cx-order-summary .cx-summary-row {
  padding: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
}
cx-order-summary .cx-summary-savings {
  color: var(--cx-color-success);
}
cx-order-summary .cx-summary-total {
  font-weight: var(--cx-font-weight-bold);
}
cx-order-summary .cx-coupon-code-pill {
  background: var(--cx-g-color-light);
  padding-top: 7px;
  padding-inline-end: 15px;
  padding-bottom: 7px;
  padding-inline-start: 13px;
  margin-top: 10px;
  margin-inline-end: 10px;
  margin-bottom: 5px;
  margin-inline-start: 0;
  display: inline-flex;
  align-items: flex-start;
  border-radius: 4px;
  word-break: break-all;
}
cx-order-summary .cx-coupon-code--pill-value {
  line-height: 1.7;
}

cx-add-to-cart .quantity {
  margin: 0 0 20px;
}
cx-add-to-cart .quantity .cx-counter-stock {
  position: relative;
}
cx-add-to-cart .quantity label {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  margin: 15px 0 10px 0;
}
cx-add-to-cart .quantity .info {
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  margin: 0 15px;
  color: var(--cx-color-secondary);
}
cx-add-to-cart .repeat-icon {
  margin-inline-end: var(--cx-margin-right, 0.2rem);
  color: var(--cx-color-primary);
  text-decoration-color: var(--cx-color-primary);
}
cx-add-to-cart .buyItAgainLink {
  color: var(--cx-color-primary);
}

cx-cart-coupon {
  padding-inline-end: 0;
  padding-inline-start: 3rem;
  padding-top: 0;
  padding-bottom: 1rem;
}
@media (max-width: 767.98px) {
  cx-cart-coupon {
    padding-top: 2rem;
    padding-inline-start: 0;
  }
}
@media (max-width: 575.98px) {
  cx-cart-coupon {
    padding-inline-start: 0;
    padding-top: 0;
  }
}
@media (min-width: 768px) {
  cx-cart-coupon .form-group .col-md-4 {
    padding: 0;
  }
}
cx-cart-coupon .cx-cart-coupon-title {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  margin: 1.125rem 0 0.5rem;
}
cx-cart-coupon .cx-cart-coupon-container {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 0px;
}
cx-cart-coupon .cx-cart-coupon-container input {
  grid-area: 1/1/2/2;
}
cx-cart-coupon .cx-cart-coupon-container button {
  grid-area: 1/2/2/3;
}
cx-cart-coupon .cx-cart-coupon-container cx-form-errors {
  grid-area: 2/1/3/3;
}
cx-cart-coupon .cx-available-coupon .title {
  font-weight: bold;
}
cx-cart-coupon .cx-available-coupon .message {
  display: flex;
  flex-wrap: wrap;
}
cx-cart-coupon .cx-available-coupon .scroll {
  display: block;
  height: auto;
  box-sizing: border-box;
  max-height: 240px;
  overflow-y: auto;
}
cx-cart-coupon .cx-available-coupon .card {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  display: inline-flex;
  align-items: flex-start;
  padding: var(--cx-padding, 0.25rem 0.25rem);
  word-break: break-all;
  margin-top: 0.25rem;
  margin-inline-end: 0.25rem;
  margin-bottom: 0;
  margin-inline-start: 0;
  background: var(--cx-color-background);
  border: 1px solid #f1f1f1;
  border-radius: 0.25rem;
}
cx-cart-coupon button.link {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  color: var(--cx-color-text);
  border-width: 0;
  text-decoration: none;
  background: var(--cx-color-background);
}
cx-cart-coupon button.link:hover {
  text-decoration: underline;
}

cx-applied-coupons .cx-applied-coupon-title {
  display: flex;
  flex-wrap: wrap;
}
cx-applied-coupons .cx-coupon-list-wrap {
  margin-bottom: 1rem;
}
cx-applied-coupons .cx-coupon-card-grid {
  display: grid;
}
cx-applied-coupons .coupon-summary {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  display: inline-flex;
  align-items: flex-start;
  padding: var(--cx-padding, 0.25rem 0.25rem);
  word-break: break-all;
  margin-top: 0.25rem;
  margin-inline-end: 0.25rem;
  margin-bottom: 0;
  margin-inline-start: 0;
}
cx-applied-coupons .cx-coupon-card {
  background: var(--cx-color-background);
  border: 1px solid #f1f1f1;
  border-radius: 0.25rem;
}
@media (max-width: 767.98px) {
  cx-applied-coupons .cx-coupon-card-grid {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
cx-applied-coupons .textonly {
  margin: 0 10px 10px 0;
  font-size: 14px;
}
cx-applied-coupons .cx-coupon-apply {
  display: inline-flex;
  align-items: center;
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  padding: 1rem 1rem;
  word-break: break-all;
}
cx-applied-coupons .cx-coupon-apply .cx-cart-coupon-code {
  flex: 1;
}
cx-applied-coupons .cx-coupon-apply .close {
  padding-inline-start: 0.7rem;
}

cx-item-counter {
  width: 146px;
}
cx-item-counter input {
  text-align: center;
}
cx-item-counter input[type=number]::-webkit-inner-spin-button, cx-item-counter input[type=number]::-webkit-outer-spin-button {
  appearance: none;
}
cx-item-counter input[type=number] {
  -moz-appearance: textfield;
}
cx-item-counter.readonly button {
  display: none;
}
cx-item-counter.readonly input {
  border: none;
  cursor: text;
  width: 65px;
}
cx-item-counter:not(.readonly) {
  border-radius: 4px;
  border: solid 1px var(--cx-color-light);
  display: inline-flex;
}
cx-item-counter:not(.readonly) input {
  border: solid 1px var(--cx-color-light);
  border-width: 0 1px;
  padding: 12px 9px;
  max-height: 48px;
  min-width: 46%;
  max-width: 46%;
}
cx-item-counter:not(.readonly) input:focus {
  background: var(--cx-color-background);
}
cx-item-counter button {
  background-color: var(--cx-color-transparent);
  border: none;
  color: var(--cx-color-text);
  cursor: pointer;
  margin: 0;
  max-height: 48px;
  min-width: 27%;
  max-width: 27%;
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.6);
}
cx-item-counter button:hover {
  color: var(--cx-color-primary);
}
cx-item-counter button:focus {
  color: var(--cx-color, var(--cx-color-primary));
  cursor: pointer;
  font-weight: var(--cx-font-weight-bold);
}
cx-item-counter button:disabled {
  color: var(--cx-color-light);
}
cx-item-counter :disabled {
  cursor: not-allowed;
}

cx-save-for-later {
  display: block;
}
cx-save-for-later .cart-details-wrapper {
  padding: 2rem 0;
}
cx-save-for-later .cx-empty-cart-info {
  margin-top: 3rem;
}

cx-cart-validation-warnings cx-icon {
  font-size: 20px;
}
cx-cart-validation-warnings .alert-icon {
  margin-inline-end: var(--cx-margin-right, 1rem);
}
cx-cart-validation-warnings .alert {
  justify-content: flex-start;
  text-align: var(--cx-text-align, left);
}
cx-cart-validation-warnings .alert .close {
  right: 1.75rem;
}

cx-cart-item-validation-warning {
  width: 100%;
}
cx-cart-item-validation-warning cx-icon {
  font-size: 20px;
}
cx-cart-item-validation-warning .alert-icon {
  margin-inline-end: var(--cx-margin-right, 1rem);
}
cx-cart-item-validation-warning .alert {
  justify-content: flex-start;
  text-align: var(--cx-text-align, start);
}
cx-cart-item-validation-warning .alert .close {
  right: 1.75rem;
}

cx-cart-proceed-to-checkout {
  padding-inline-end: 0;
  padding-inline-start: 3rem;
  padding-top: 0;
  padding-bottom: 1.5rem;
}
@media (max-width: 767.98px) {
  cx-cart-proceed-to-checkout {
    align-self: flex-end;
    padding-bottom: 2rem;
    padding-inline-start: 0;
  }
}
@media (max-width: 575.98px) {
  cx-cart-proceed-to-checkout {
    padding: 2rem 0 3rem;
  }
}

cx-clear-cart-dialog {
  background-color: rgba(0, 0, 0, 0.5);
}
cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .cx-clear-cart-header .cx-clear-cart-title {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .cx-clear-cart-body .clear-cart-msg {
  margin-bottom: 1.5rem;
}
cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .cx-clear-cart-body .clear-cart-warning {
  color: var(--cx-color-danger);
  margin-bottom: 1.5rem;
}
cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .cx-clear-cart-footer {
  display: flex;
}
cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .cx-clear-cart-footer button {
  flex: 0 0 calc(50% - 0.5rem);
}
cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .cx-clear-cart-footer button:first-child {
  margin-inline-end: 0.5rem;
}
cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .cx-clear-cart-footer button:last-child {
  margin-inline-start: 0.5rem;
}
cx-clear-cart-dialog .cx-clear-cart-dialog .cx-clear-cart-container .cx-clear-cart-footer button:focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}

cx-clear-cart .clear-cart-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0 0 40px;
}

cx-page-layout.CartPageTemplate {
  max-width: var(--cx-page-width-max);
  padding: 0 1.5rem;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}
cx-page-layout.CartPageTemplate .TopContent {
  --cx-flex-basis: 68%;
  flex-direction: column;
  flex-wrap: nowrap;
}
@media (max-width: 767.98px) {
  cx-page-layout.CartPageTemplate .TopContent {
    --cx-flex-basis: 100%;
  }
}
cx-page-layout.CartPageTemplate .CenterRightContentSlot {
  --cx-flex-basis: 30%;
  flex-direction: column;
  justify-content: flex-start;
}
@media (min-width: 992px) {
  cx-page-layout.CartPageTemplate .CenterRightContentSlot > * {
    flex: none;
  }
}
@media (max-width: 767.98px) {
  cx-page-layout.CartPageTemplate .CenterRightContentSlot {
    --cx-flex-basis: 50%;
    justify-content: flex-end;
    flex-direction: row;
  }
  cx-page-layout.CartPageTemplate .CenterRightContentSlot cx-cart-totals,
  cx-page-layout.CartPageTemplate .CenterRightContentSlot cx-cart-coupon,
  cx-page-layout.CartPageTemplate .CenterRightContentSlot cx-cart-proceed-to-checkout,
  cx-page-layout.CartPageTemplate .CenterRightContentSlot cx-quote-request-button {
    min-width: 100%;
  }
  cx-page-layout.CartPageTemplate .CenterRightContentSlot cx-cart-coupon {
    order: 2;
  }
  cx-page-layout.CartPageTemplate .CenterRightContentSlot cx-cart-quick-order-form {
    order: 3;
  }
  cx-page-layout.CartPageTemplate .CenterRightContentSlot cx-cart-totals {
    order: 4;
  }
  cx-page-layout.CartPageTemplate .CenterRightContentSlot cx-cart-proceed-to-checkout {
    order: 5;
  }
  cx-page-layout.CartPageTemplate .CenterRightContentSlot cx-quote-request-button {
    order: 6;
  }
}
@media (max-width: 575.98px) {
  cx-page-layout.CartPageTemplate .CenterRightContentSlot {
    --cx-flex-basis: 100%;
    flex-direction: unset;
  }
  cx-page-layout.CartPageTemplate .CenterRightContentSlot cx-cart-totals {
    max-width: none;
  }
  cx-page-layout.CartPageTemplate .CenterRightContentSlot cx-cart-quick-form,
  cx-page-layout.CartPageTemplate .CenterRightContentSlot cx-cart-coupon,
  cx-page-layout.CartPageTemplate .CenterRightContentSlot cx-cart-proceed-to-checkout,
  cx-page-layout.CartPageTemplate .CenterRightContentSlot cx-quote-request-button {
    max-width: 100%;
    padding-inline-end: 0;
    padding-inline-start: 0;
  }
}

cx-saved-cart-list .cx-saved-cart-list-header {
  padding: 3rem 0 1rem 0;
}
cx-saved-cart-list .cx-saved-cart-list-table {
  padding: 1.5rem 0 1.125rem 0;
  margin-bottom: 0;
  border-width: 1px 0 1px 0;
  border-color: var(--cx-color-light);
  border-style: solid;
}
cx-saved-cart-list .cx-saved-cart-list-table .cx-saved-cart-list-th-qty {
  width: 150px;
}
@media (min-width: 768px) {
  cx-saved-cart-list .cx-saved-cart-list-table .cx-saved-cart-list-th-qty {
    text-align: center;
  }
}
@media (min-width: 768px) {
  cx-saved-cart-list .cx-saved-cart-list-table .cx-saved-cart-list-th-total {
    text-align: end;
  }
}
@media (min-width: 992px) {
  cx-saved-cart-list .cx-saved-cart-list-table .cx-table-label-mobile {
    display: none;
  }
}
cx-saved-cart-list .cx-saved-cart-list-table tr {
  width: 100%;
}
cx-saved-cart-list .cx-saved-cart-list-table tr:hover {
  background-color: var(--cx-color-background);
}
@media (max-width: 767.98px) {
  cx-saved-cart-list .cx-saved-cart-list-table tr {
    border-width: 1px 0 0 0;
    border-color: var(--cx-color-light);
    border-style: solid;
  }
  cx-saved-cart-list .cx-saved-cart-list-table tr:first-child {
    border-width: 0;
    padding: 1.25rem 0 0 0;
  }
}
cx-saved-cart-list .cx-saved-cart-list-table th {
  padding: 1rem 0;
  text-align: start;
}
cx-saved-cart-list .cx-saved-cart-list-table th:first-child {
  padding-inline-start: 20px;
}
cx-saved-cart-list .cx-saved-cart-list-table th:last-child {
  text-align: end;
  padding-inline-end: 20px;
}
cx-saved-cart-list .cx-saved-cart-list-table td {
  padding-top: 1.6875rem;
  padding-inline-end: 0.5rem;
  padding-bottom: 1.75rem;
  padding-inline-start: 0;
}
@media (min-width: 768px) {
  cx-saved-cart-list .cx-saved-cart-list-table td {
    text-align: start;
  }
  cx-saved-cart-list .cx-saved-cart-list-table td:last-child {
    text-align: end;
    line-height: var(--cx-line-height);
    padding-inline-end: 20px;
  }
}
@media (max-width: 767.98px) {
  cx-saved-cart-list .cx-saved-cart-list-table td {
    width: 100%;
    display: flex;
    border-width: 0;
    padding: 0 1.25rem;
  }
  cx-saved-cart-list .cx-saved-cart-list-table td:first-child {
    padding-top: 1.25rem;
  }
  cx-saved-cart-list .cx-saved-cart-list-table td:last-child {
    padding-top: 1rem;
    padding-bottom: 1.25rem;
  }
}
@media (max-width: 767.98px) {
  cx-saved-cart-list .cx-saved-cart-list-table .cx-saved-cart-list-thead-mobile {
    display: none;
  }
}
cx-saved-cart-list .cx-saved-cart-list-table .cx-saved-cart-list-cart-name {
  padding-inline-start: 20px;
}
@media (min-width: 992px) {
  cx-saved-cart-list .cx-saved-cart-list-table .cx-saved-cart-list-cart-name {
    width: 20%;
  }
}
cx-saved-cart-list .cx-saved-cart-list-table .cx-saved-cart-list-cart-name .cx-saved-cart-list-value {
  text-decoration: underline;
  word-break: break-word;
}
@media (min-width: 992px) {
  cx-saved-cart-list .cx-saved-cart-list-table .cx-saved-cart-list-quantity {
    text-align: center;
  }
}
@media (min-width: 992px) {
  cx-saved-cart-list .cx-saved-cart-list-table .cx-saved-cart-list-total {
    text-align: end;
  }
}
cx-saved-cart-list .cx-saved-cart-list-table .cx-saved-cart-list-label {
  color: var(--cx-color-secondary);
}
.cxFeat_a11ySavedCartsZoom cx-saved-cart-list .cx-saved-cart-list-table .cx-saved-cart-list-label {
  text-align: start;
}

@media (max-width: 767.98px) {
  cx-saved-cart-list .cx-saved-cart-list-table .cx-saved-cart-list-label {
    font-size: var(--cx-font-size, 0.875rem);
    font-weight: var(--cx-font-weight-bold);
    min-width: 140px;
  }
}
cx-saved-cart-list .cx-saved-cart-list-table .cx-saved-cart-list-value {
  color: var(--cx-color-text);
}
@media (max-width: 767.98px) {
  cx-saved-cart-list .cx-saved-cart-list-table .cx-saved-cart-list-value {
    font-size: var(--cx-font-size, 1rem);
    font-weight: "normal";
  }
}
cx-saved-cart-list .cx-saved-cart-list-no-saved-carts {
  font-size: var(--cx-font-size, 1rem);
  font-weight: "normal";
  min-height: auto;
}
@media (max-width: 767.98px) {
  cx-saved-cart-list .cx-saved-cart-list-no-saved-carts {
    padding-inline-start: 1.25rem;
    padding-inline-end: 1.25rem;
    min-height: auto;
  }
}
@media (max-width: 767.98px) {
  .cxFeat_a11ySavedCartsZoom cx-saved-cart-list .cx-saved-cart-make-active {
    min-height: unset;
    padding: 0;
  }
}

cx-saved-cart-details-overview .cx-cart-summary {
  background-color: var(--cx-color-background);
}
@media (max-width: 575.98px) {
  cx-saved-cart-details-overview .cx-cart-summary {
    background-color: var(--cx-color-inverse);
  }
}
cx-saved-cart-details-overview .cx-cart-summary .container {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0;
}
@media (max-width: 767.98px) {
  cx-saved-cart-details-overview .cx-cart-summary .container {
    max-width: 100%;
    min-width: 100%;
    padding: 0 1.25rem;
  }
}
@media (max-width: 575.98px) {
  cx-saved-cart-details-overview .cx-cart-summary .container {
    flex-direction: column;
    padding: 1.25rem;
  }
}
cx-saved-cart-details-overview .cx-cart-summary .container .cx-summary-card {
  flex: 1;
  padding: 0 15px;
}
@media (min-width: 992px) {
  cx-saved-cart-details-overview .cx-cart-summary .container .cx-summary-card:not(:last-of-type) {
    border-inline-end: 1px solid var(--cx-color-text);
  }
}
@media (max-width: 767.98px) {
  cx-saved-cart-details-overview .cx-cart-summary .container .cx-summary-card {
    flex: 0 0 33%;
  }
}
@media (max-width: 575.98px) {
  cx-saved-cart-details-overview .cx-cart-summary .container .cx-summary-card {
    flex: 1;
    background-color: var(--cx-color-inverse);
    border-width: 1px;
    border-style: solid;
    border-color: var(--cx-color-light);
    margin: 0.625rem 0;
  }
}
cx-saved-cart-details-overview .cx-cart-summary .container .cx-summary-card .cx-card-title {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: bold;
  margin-bottom: 0.5rem;
}
cx-saved-cart-details-overview .cx-cart-summary .container .cx-summary-card .cx-edit-container {
  display: flex;
  justify-content: space-between;
  word-break: break-word;
}
cx-saved-cart-details-overview .cx-cart-summary .container .cx-summary-card .cx-edit-container .cx-edit-cart {
  height: 100%;
  margin: 1rem;
  border: none;
  background-color: var(--cx-color-transparent);
}
cx-saved-cart-details-overview .cx-cart-summary .container .cx-summary-card .cx-card-description {
  max-width: 100%;
  word-break: break-word;
}

cx-saved-cart-details-items .cx-value button[type=button] {
  margin-bottom: 0;
}

cx-saved-cart-details-action .cx-saved-cart-restore-btns {
  justify-content: flex-end;
  margin: 2rem 0;
}
@media (max-width: 767.98px) {
  cx-saved-cart-details-action .cx-saved-cart-restore-btns > *:first-child {
    margin-bottom: 1em;
  }
}

cx-saved-cart-form-dialog {
  background-color: rgba(0, 0, 0, 0.5);
}
cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-header .cx-saved-cart-form-title {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-body .cx-saved-cart-form-row {
  margin-bottom: 1.5rem;
}
cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-body .cx-saved-cart-form-row .cx-saved-cart-values-container {
  display: flex;
}
@media (min-width: 576px) {
  cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-body .cx-saved-cart-form-row .cx-saved-cart-values-container {
    padding-inline-start: 3rem;
  }
}
cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-body .cx-saved-cart-form-row .cx-saved-cart-values-container .cx-saved-cart-label {
  min-width: 140px;
}
cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-body .cx-saved-cart-form-row .cx-saved-cart-values-container .cx-saved-cart-value {
  font-weight: bold;
  word-break: break-word;
}
cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-body .cx-saved-cart-form-row .cx-saved-carts-label {
  font-weight: bold;
}
cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-body .cx-saved-cart-form-row .cx-saved-carts-label-optional {
  font-weight: normal;
}
cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-body .cx-saved-cart-form-row .cx-saved-carts-input-hint {
  font-size: 0.75rem;
  font-style: italic;
  text-align: end;
}
cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-body .cx-copy-saved-cart-row {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: center;
}
cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-body .cx-copy-saved-cart-row .cx-copy-saved-cart-label {
  margin-bottom: 0;
}
cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-body .cx-copy-saved-cart-row label {
  flex: 1;
}
cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-footer {
  display: flex;
}
cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-footer button {
  flex: 0 0 calc(50% - 0.5rem);
}
cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-footer button:first-child {
  margin-inline-end: 0.5rem;
}
cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-footer button:last-child {
  margin-inline-start: 0.5rem;
}
cx-saved-cart-form-dialog .cx-saved-cart-form-dialog .cx-saved-cart-form-container .cx-saved-cart-form-footer button:focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}

cx-add-to-saved-cart {
  max-height: 70px;
}
cx-add-to-saved-cart .cx-add-to-saved-cart-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 3rem;
}
cx-add-to-saved-cart .cx-add-to-saved-cart-container .cx-action-link:nth-child(n+2) {
  margin-inline-end: 0;
}
cx-add-to-saved-cart .cx-add-to-saved-cart-container .cx-action-link:nth-child(n+2):before {
  content: "|";
  color: var(--cx-color-light);
  display: inline-block;
  text-decoration: none;
  margin-inline-start: 0.313rem;
  margin-inline-end: 0.313rem;
}
.cxFeat_a11yExpandedFocusIndicator cx-add-to-saved-cart .cx-add-to-saved-cart-container .cx-action-link:nth-child(n+2) {
  margin-inline-end: unset;
  position: relative;
  margin-inline-start: 1rem;
}
.cxFeat_a11yExpandedFocusIndicator cx-add-to-saved-cart .cx-add-to-saved-cart-container .cx-action-link:nth-child(n+2):before {
  display: unset;
  position: absolute;
  left: -0.75rem;
  margin-inline-start: unset;
  margin-inline-end: unset;
}

@media (min-width: 576px) {
  cx-add-to-saved-cart .cx-add-to-saved-cart-container {
    justify-content: flex-end;
  }
}
cx-add-to-saved-cart a.disabled {
  color: gray;
  cursor: not-allowed;
  text-decoration: underline;
}
cx-add-to-saved-cart button:focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}

cx-cart-quick-order-form {
  padding-bottom: 1.5rem;
  padding-top: 0;
  padding-inline-end: 0;
  padding-inline-start: 3rem;
}
@media (max-width: 767.98px) {
  cx-cart-quick-order-form {
    max-width: 50%;
    padding-bottom: 2rem;
    padding-inline-end: 0;
    padding-inline-start: 0;
    min-width: 100%;
  }
}
@media (max-width: 575.98px) {
  cx-cart-quick-order-form {
    max-width: 100%;
    padding-inline-end: 0;
    padding-top: 1rem;
  }
}
cx-cart-quick-order-form .cx-cart-quick-order-form-title {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  margin: 1.125rem 0 0.5rem;
}
cx-cart-quick-order-form .form-group {
  margin-bottom: 0;
}
cx-cart-quick-order-form .cx-cart-quick-order-form-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
cx-cart-quick-order-form .cx-cart-quick-order-form-container .cx-cart-quick-order-form-label {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-cart-quick-order-form .cx-cart-quick-order-form-container .cx-cart-quick-order-form-productID {
  width: 65%;
}
@media (max-width: 767.98px) {
  cx-cart-quick-order-form .cx-cart-quick-order-form-container .cx-cart-quick-order-form-productID {
    width: 70%;
  }
}
cx-cart-quick-order-form .cx-cart-quick-order-form-container .cx-cart-quick-order-form-qty {
  text-align: center;
  width: 30%;
}
cx-cart-quick-order-form .cx-cart-quick-order-form-container .cx-cart-quick-order-form-qty .input-quantity {
  text-align: center;
}
@media (max-width: 767.98px) {
  cx-cart-quick-order-form .cx-cart-quick-order-form-container .cx-cart-quick-order-form-qty {
    width: 25%;
  }
}
cx-cart-quick-order-form .cx-cart-quick-order-form-container button {
  width: 100%;
  margin-top: 10px;
}
cx-cart-quick-order-form .cx-cart-quick-order-form-container input[type=number]::-webkit-inner-spin-button, cx-cart-quick-order-form .cx-cart-quick-order-form-container input[type=number]::-webkit-outer-spin-button {
  appearance: none;
}
cx-cart-quick-order-form .cx-cart-quick-order-form-container input[type=number] {
  -moz-appearance: textfield;
}

cx-quick-order-form .quick-order-form-container {
  display: flex;
  position: relative;
}
cx-quick-order-form .quick-order-form-input {
  margin-inline-end: 15px;
  position: relative;
  width: 330px;
}
cx-quick-order-form .quick-order-form-input .form-control {
  padding: 0 46px 0 12px;
}
cx-quick-order-form .quick-order-form-input input {
  height: 47px;
}
cx-quick-order-form .quick-order-form-input .quick-order-form-search-icon,
cx-quick-order-form .quick-order-form-input .quick-order-form-reset-icon {
  background: transparent;
  border: none;
  color: var(--cx-color-medium);
  display: block;
  font-size: 24px;
  position: absolute;
  right: 6px;
  top: 4px;
}
cx-quick-order-form .quick-order-form-input .quick-order-form-reset-icon {
  cursor: pointer;
}
cx-quick-order-form .quick-order-form-input .list-limit-reached-text {
  bottom: -15px;
  color: var(--cx-color-danger);
  font-size: 12px;
  font-weight: normal;
  padding-inline-start: 2px;
  position: absolute;
}
cx-quick-order-form .quick-order-results {
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid var(--cx-color-light);
  color: #000;
  display: none;
  font-size: 13px;
  position: absolute;
  top: 47px;
  width: 330px;
  z-index: 20;
}
cx-quick-order-form .quick-order-results .quick-order-results-products {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
cx-quick-order-form .quick-order-results .quick-order-results-products .quick-order-results-product-container {
  border-top: 1px solid var(--cx-color-light);
}
cx-quick-order-form .quick-order-results .quick-order-results-products .quick-order-results-product-container:first-of-type {
  border-top: none;
}
cx-quick-order-form .quick-order-results .quick-order-results-products .quick-order-results-product-container .quick-order-results-product {
  background-color: var(--cx-color-white);
  border: none;
  cursor: pointer;
  display: grid;
  grid-column-gap: 16px;
  padding: 10px;
  text-align: start;
  width: 100%;
}
cx-quick-order-form .quick-order-results .quick-order-results-products .quick-order-results-product-container .quick-order-results-product.has-media {
  grid-template-columns: 50px 1fr;
}
cx-quick-order-form .quick-order-results .quick-order-results-products .quick-order-results-product-container .quick-order-results-product:hover, cx-quick-order-form .quick-order-results .quick-order-results-products .quick-order-results-product-container .quick-order-results-product:focus, cx-quick-order-form .quick-order-results .quick-order-results-products .quick-order-results-product-container .quick-order-results-product.active {
  background-color: var(--cx-color-light);
}
cx-quick-order-form .quick-order-results .quick-order-results-products .quick-order-results-product-container .quick-order-results-product .media {
  grid-row: 1/4;
}
cx-quick-order-form .quick-order-results .quick-order-results-products .quick-order-results-product-container .quick-order-results-product .name {
  font-weight: bold;
}
cx-quick-order-form .quick-order-results .quick-order-no-results {
  align-items: center;
  display: flex;
  font-size: 1rem;
  height: 37px;
  justify-content: flex-start;
  padding: 0 10px;
}

.quick-order-searchbox-is-active .quick-order-results {
  display: block;
}

cx-quick-order .quick-order-errors,
cx-quick-order .quick-order-successes,
cx-quick-order .quick-order-warnings {
  padding-inline-start: 30px;
}
cx-quick-order .quick-order-errors .quick-order-error-item,
cx-quick-order .quick-order-errors .quick-order-success-item,
cx-quick-order .quick-order-errors .quick-order-warning-item,
cx-quick-order .quick-order-successes .quick-order-error-item,
cx-quick-order .quick-order-successes .quick-order-success-item,
cx-quick-order .quick-order-successes .quick-order-warning-item,
cx-quick-order .quick-order-warnings .quick-order-error-item,
cx-quick-order .quick-order-warnings .quick-order-success-item,
cx-quick-order .quick-order-warnings .quick-order-warning-item {
  padding-bottom: 3px;
}
cx-quick-order .quick-order-form-body {
  background-color: #f4f4f4;
  margin-top: 20px;
  padding: 27px 22px;
  width: 100%;
}
cx-quick-order .quick-order-footer {
  justify-content: flex-end;
  margin-top: 40px;
}

cx-wish-list td.cx-actions cx-add-to-cart {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 767.98px) {
  cx-wish-list td.cx-actions cx-add-to-cart {
    width: 100%;
  }
  cx-wish-list td.cx-actions cx-add-to-cart form {
    width: inherit;
  }
  cx-wish-list td.cx-actions cx-add-to-cart button {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  cx-wish-list {
    --cx-max-width: 75%;
  }
  cx-wish-list .cx-item-list-row:last-of-type {
    border: none;
  }
}
@media (max-width: 575.98px) {
  cx-wish-list {
    --cx-max-width: 100%;
  }
}

@media (min-width: 992px) {
  cx-add-to-wishlist {
    grid-column: 2;
    padding: 0 20px;
  }
}
cx-add-to-wishlist .btn {
  text-transform: none;
}
cx-add-to-wishlist .button-remove {
  vertical-align: inherit;
}
cx-add-to-wishlist .button-add, cx-add-to-wishlist .button-remove {
  padding: 0;
}
cx-add-to-wishlist .button-text {
  margin-inline-start: 0.3em;
}
cx-add-to-wishlist .button-add-link {
  padding-inline-start: 0;
}

cx-import-export-order-entries {
  display: flex;
  justify-content: flex-start;
  margin: 1.5rem 0;
  max-width: var(--cx-page-width-max);
}
cx-import-export-order-entries > * {
  align-self: flex-start;
}
cx-import-export-order-entries > *:before {
  content: "|";
  color: var(--cx-color-light);
  display: inline-block;
  text-decoration: none;
  margin-inline-start: 0.313rem;
  margin-inline-end: 0.313rem;
}
cx-import-export-order-entries > *:first-child:before {
  display: none;
}
cx-import-export-order-entries cx-import-order-entries {
  width: auto;
  margin: 0;
}
cx-import-export-order-entries cx-export-order-entries {
  width: auto !important;
  margin: 0 !important;
  padding: 0 !important;
  min-width: 0 !important;
}

cx-import-order-entries {
  width: 100%;
  max-width: var(--cx-page-width-max);
  margin: 1.25rem auto;
}

cx-export-order-entries {
  width: 100%;
  max-width: var(--cx-page-width-max);
  margin: 1.25rem auto;
  display: flex;
  align-items: center;
}

@media (max-width: 767.98px) {
  cx-export-order-entries.container {
    max-width: 100%;
    min-width: 100%;
    padding-inline-start: 2.5rem;
  }
}
@media (max-width: 575.98px) {
  cx-export-order-entries.container {
    max-width: 100%;
    min-width: 100%;
    padding-inline-start: 1rem;
  }
}

cx-import-entries-dialog {
  background-color: rgba(0, 0, 0, 0.5);
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container {
  pointer-events: none;
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container button,
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container input,
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container textarea {
  pointer-events: auto;
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .cx-import-entries-header .cx-import-entries-title {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-form,
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-to-new-saved-cart-form {
  background-color: var(--cx-color-inverse);
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-form .cx-import-entries-subtitle,
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-to-new-saved-cart-form .cx-import-entries-subtitle {
  font-weight: var(--cx-font-weight-bold);
  margin-bottom: 0.5rem;
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-form .cx-import-entries-row,
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-to-new-saved-cart-form .cx-import-entries-row {
  margin-bottom: 1.5rem;
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-form .cx-import-entries-row .cx-saved-cart-values-container,
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-to-new-saved-cart-form .cx-import-entries-row .cx-saved-cart-values-container {
  display: flex;
}
@media (min-width: 576px) {
  cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-form .cx-import-entries-row .cx-saved-cart-values-container,
  cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-to-new-saved-cart-form .cx-import-entries-row .cx-saved-cart-values-container {
    padding-inline-start: 3rem;
  }
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-form .cx-import-entries-row .cx-saved-cart-values-container .cx-saved-cart-label,
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-to-new-saved-cart-form .cx-import-entries-row .cx-saved-cart-values-container .cx-saved-cart-label {
  min-width: 140px;
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-form .cx-import-entries-row .cx-saved-cart-values-container .cx-saved-cart-value,
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-to-new-saved-cart-form .cx-import-entries-row .cx-saved-cart-values-container .cx-saved-cart-value {
  font-weight: var(--cx-font-weight-bold);
  word-break: break-word;
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-form .cx-import-entries-row .cx-import-entries-label,
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-to-new-saved-cart-form .cx-import-entries-row .cx-import-entries-label {
  font-weight: var(--cx-font-weight-bold);
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-form .cx-import-entries-row .cx-import-entries-label-optional,
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-to-new-saved-cart-form .cx-import-entries-row .cx-import-entries-label-optional {
  font-weight: var(--cx-font-weight-normal);
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-form .cx-import-entries-row .cx-import-entries-input-hint,
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-to-new-saved-cart-form .cx-import-entries-row .cx-import-entries-input-hint {
  font-size: 0.75rem;
  font-style: italic;
  text-align: end;
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .cx-import-entries-footer {
  display: flex;
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .cx-import-entries-footer button {
  flex: 0 0 calc(50% - 0.5rem);
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .cx-import-entries-footer button:first-child {
  margin-inline-end: 0.5rem;
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .cx-import-entries-footer button:last-child {
  margin-inline-start: 0.5rem;
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container .cx-import-entries-footer button:focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-summary ul {
  margin: -0.75rem 0 1.5rem;
  overflow: auto;
  max-height: 150px;
  pointer-events: all;
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-summary ul li {
  padding: 0;
  font-size: 0.8em;
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-summary cx-icon {
  padding-inline-end: 5px;
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-summary cx-icon.success {
  color: var(--cx-color-success);
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-summary cx-icon.warning {
  color: var(--cx-color-warning);
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-summary cx-icon.error {
  color: var(--cx-color-danger);
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-summary .cx-import-entries-summary-footer {
  display: flex;
  justify-content: center;
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-summary .cx-import-entries-summary-footer button {
  flex: 0 0 calc(50% - 0.5rem);
}
cx-import-entries-dialog .cx-import-entries-dialog .cx-import-entries-container cx-import-entries-summary .cx-import-entries-summary-footer button:focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}